.fighter.fighter-captain_falcon {
    &.fighter-recovering {
        z-index: 2;

        &.fighter-one {
            animation: captainFalconRecover 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }

        &.fighter-two {
            animation: captainFalconRecoverReverse 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
    }
}

.fighter-captain_falcon-effects {
    &.fighter-one-effects {
        &.fighter-punishing-effects {
            div {
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                animation-play-state: running;

                &:first-of-type {
                    height: 24px;
                    width: 24px;
                    background-image: url('/assets/effects/captain_falcon/falcon_punch.png');
                    background-size: 24px;
                    background-repeat: no-repeat;
                    position: absolute;
                    top: calc(65% - 24px);
                    left: calc(75% - 48px);
                    opacity: 0;
                    animation: captainFalconFalconPunch 1s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                } 

                &:nth-of-type(3) {
                    height: 24px;
                    width: 24px;
                    background-image: url('/assets/effects/captain_falcon/falcon_punch.png');
                    background-size: 96px;
                    background-position: center;
                    background-repeat: no-repeat;
                    position: absolute;
                    top: calc(65% - 24px);
                    left: calc(75% - 48px);
                    border-radius: 24px;
                    overflow: hidden;
                    opacity: 0.5;
                    border: solid 2px #ffffff;
                    animation: captainFalconFalconPunchWarmUp 0.25s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                }
                
                &:nth-of-type(2) {
                    height: 32px;
                    width: 32px;
                    background-image: url('/assets/effects/captain_falcon/falcon_punch.png');
                    background-size: 96px;
                    background-position: center;
                    background-repeat: no-repeat;
                    position: absolute;
                    top: calc(65% - 28px);
                    left: calc(75% - 52px);
                    border-radius: 32px;
                    overflow: hidden;
                    opacity: 0.8;
                    border: solid 2px #ffffff;
                    filter: brightness(2);
                    animation: captainFalconFalconPunchWarmUp 0.25s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                }
            }
        }

        &.fighter-recovering-effects {
            div:first-of-type {
                height: 48px;
                width: 48px;
                background-image: url('/assets/effects/captain_falcon/falcon_punch.png');
                background-size: 48px;
                background-repeat: no-repeat;
                position: absolute;
                animation: captainFalconRecoverEffects 1s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                top: calc(65% - 48px);
                opacity: 0.5;
                z-index: -1;
            }
        }
    }

    &.fighter-two-effects {
        &.fighter-punishing-effects {
            div {
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                animation-play-state: running;

                &:first-of-type {
                    height: 24px;
                    width: 24px;
                    background-image: url('/assets/effects/captain_falcon/falcon_punch_reverse.png');
                    background-size: 24px;
                    background-repeat: no-repeat;
                    position: absolute;
                    top: calc(65% - 24px);
                    right: calc(75% - 48px);
                    opacity: 0;
                    animation: captainFalconFalconPunchReverse 1s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                } 

                &:nth-of-type(3) {
                    height: 24px;
                    width: 24px;
                    background-image: url('/assets/effects/captain_falcon/falcon_punch_reverse.png');
                    background-size: 96px;
                    background-position: center;
                    background-repeat: no-repeat;
                    position: absolute;
                    top: calc(65% - 24px);
                    left: calc(75% - 48px);
                    border-radius: 24px;
                    overflow: hidden;
                    opacity: 0.5;
                    border: solid 2px #ffffff;
                    animation: captainFalconFalconPunchWarmUp 0.25s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                }
                
                &:nth-of-type(2) {
                    height: 32px;
                    width: 32px;
                    background-image: url('/assets/effects/captain_falcon/falcon_punch.png');
                    background-size: 96px;
                    background-position: center;
                    background-repeat: no-repeat;
                    position: absolute;
                    top: calc(65% - 28px);
                    left: calc(75% - 52px);
                    border-radius: 32px;
                    overflow: hidden;
                    opacity: 0.8;
                    border: solid 2px #ffffff;
                    filter: brightness(2);
                    animation: captainFalconFalconPunchWarmUp 0.25s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                }
            }
        }
        
        &.fighter-recovering-effects {
            div:first-of-type {
                height: 48px;
                width: 48px;
                background-image: url('/assets/effects/captain_falcon/falcon_punch_reverse.png');
                background-size: 48px;
                background-repeat: no-repeat;
                position: absolute;
                animation: captainFalconRecoverEffectsReverse 1s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                top: calc(65% - 48px);
                opacity: 0.5;
                z-index: -1;
            }
        }
    }
}

@keyframes captainFalconFalconPunchWarmUp {
    0% {
        transform: scale(1);
    }

    60% {
        opacity: 0;
        transform: scale(0.1);
    }
    
    100% {
        transform: scale(0.1);
        opacity: 0;
    }
    
}

@keyframes captainFalconFalconPunch {
    0% {
        opacity: 0;
        left: calc(75% - 48px);
        transform: scale(1);
    }
    
    10% {
        opacity: 1;
        left: calc(75% - 48px);
        transform: scale(4);
    }

    20% {
        left: 75%;
    }

    100% {
        left: 80%;
        opacity: 0;
        transform: scale(1);
    }
}

@keyframes captainFalconFalconPunchReverse {
    0% {
        opacity: 0;
        right: calc(75% - 48px);
        transform: scale(1);
    }
    
    10% {
        opacity: 1;
        right: calc(75% - 48px);
        transform: scale(4);
    }

    20% {
        right: 75%;
    }

    100% {
        right: 80%;
        opacity: 0;
        transform: scale(1);
    }
}


@keyframes captainFalconRecover {
    0% {
        transform: translateY(100px) rotate(0deg);
    }

    20% {
        transform: translateY(20px) rotate(0deg);
    }

    30% {
        transform: translateY(35px) rotate(0deg);
    }

    50% {
        transform: translateY(-15px) rotate(360deg);
    }

    60% {
        transform: translateY(0px) rotate(360deg);
    }

    100% {
        transform: translateY(0px) rotate(360deg);
    }
}

@keyframes captainFalconRecoverEffects {
    0% {
        transform: translateY(100px);
        left: -7.5%;
    }

    20% {
        transform: translateY(20px);
    }

    30% {
        transform: translateY(35px) rotate(0deg);
        opacity: 0;
    }

    50% {
        transform: translateY(-15px) rotate(360deg);
        opacity: 0.5
    }

    60% {
        transform: translateY(0px) rotate(360deg);
        opacity: 0.5;
    }

    70% {
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 0;
        left: calc(12.5% + 32px);
    }
}

@keyframes captainFalconRecoverReverse {
    0% {
        transform: translateY(100px) rotate(0deg);
    }

    20% {
        transform: translateY(20px) rotate(0deg);
    }

    30% {
        transform: translateY(35px) rotate(0deg);
    }

    50% {
        transform: translateY(-15px) rotate(-360deg);
    }

    60% {
        transform: translateY(0px) rotate(-360deg);
    }

    100% {
        transform: translateY(0px) rotate(-360deg);
    }
}

@keyframes captainFalconRecoverEffectsReverse {
    0% {
        transform: translateY(100px);
        right: -7.5%;
    }

    20% {
        transform: translateY(20px);
    }

    30% {
        transform: translateY(35px) rotate(0deg);
        opacity: 0;
    }

    50% {
        transform: translateY(-15px) rotate(-360deg);
        opacity: 0.5
    }

    60% {
        transform: translateY(0px) rotate(-360deg);
        opacity: 0.5;
    }

    70% {
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 0;
        right: calc(12.5% + 32px);
    }
}