
.round {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 300px;
    box-sizing: border-box;

    p {
        margin: 0;
        padding: 10px;
        background-color: #cdcdcd;
        font-weight: bold;
    }
}