$colour-dark: #000417ff;
$colour-not-quite-so-dark: lighten($colour-dark, 0.5%);
$colour-even-less-dark: lighten($colour-dark, 2%);

$colour-primary: #f50702ff;
$colour-primary-dark: #cd0a00ff;

$colour-secondary: #62c89e;
$colour-secondary-darker: darken($colour-secondary, 15%);

$colour-highlight: #f9dd9b;
