@import "../../variables";

.settings-container {
    background: $colour-dark;
    color: #ffffff;

    .settings {
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;

        &.version {
            opacity: 0.4;
        }

        dt, dd {
            box-sizing: border-box;
            flex: 50%;
            padding: 5px 10px;
            margin: 0;
            border-bottom: solid 1px #ffffff33;
            font-weight: 300;

            &:last-of-type {
                border: none;
            }
        }

        dt {
            flex: 35%;
        }

        dd {
            justify-content: flex-end;
            align-items: center;
            display: flex;
            flex: 60%;

            .char-icon {
                height: 24px;
                margin-left: 10px;
            }
        }
    }

    .button {
        margin: 30px auto 10px auto;
        display: block;
        width: 140px;
    }

    a {
        display: block;
        text-align: center;
        color: #ffffffdd;
        font-size: 14px;
    }
}