.fighter-yoshi-effects {
    &.fighter-one-effects {
        &.fighter-neutral-effects {
            div:first-of-type {
                animation: eggThrow 1.2s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                animation-fill-mode: forwards;
                animation-delay: 0.5s;
                height: 16px;
                width: 16px;
                position: absolute;
                top: calc(65% - 16px);
                background-image: url('/assets/effects/yoshi/egg.png');
                background-size: 16px;
                background-repeat: no-repeat;
                opacity: 0;
            }
            
            div:nth-of-type(2),
            div:nth-of-type(3),
            div:nth-of-type(4),
            div:nth-of-type(5) {
                animation-timing-function: linear;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                animation-delay: 1.3s;
                animation-duration: 0.4s;
                height: 8px;
                width: 8px;
                position: absolute;
                top: calc(65% - 16px);
                right: 25%;
                background-image: url('/assets/effects/yoshi/egg.png');
                background-size: 16px;
                background-position: center;
                background-repeat: no-repeat;
                opacity: 0;
            }

            div:nth-of-type(2) { animation-name: eggSmash1; }
            div:nth-of-type(3) { animation-name: eggSmash2; }
            div:nth-of-type(4) { animation-name: eggSmash3; }
            div:nth-of-type(5) { animation-name: eggSmash4; }
        }
    }

    &.fighter-two-effects {
        &.fighter-neutral-effects {
            div:first-of-type {
                animation: eggThrowReverse 1.2s;
                animation-delay: 0.5s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                animation-fill-mode: forwards;
                height: 16px;
                width: 16px;
                position: absolute;
                top: calc(65% - 16px);
                background-image: url('/assets/effects/yoshi/egg.png');
                background-size: 16px;
                background-repeat: no-repeat;
                opacity: 0;
            }

            div:nth-of-type(2),
            div:nth-of-type(3),
            div:nth-of-type(4),
            div:nth-of-type(5) {
                animation-timing-function: linear;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                animation-delay: 1.3s;
                animation-duration: 0.4s;
                height: 8px;
                width: 8px;
                position: absolute;
                top: calc(65% - 16px);
                left: 25%;
                background-image: url('/assets/effects/yoshi/egg.png');
                background-size: 16px;
                background-position: center;
                background-repeat: no-repeat;
                opacity: 0;
            }

            div:nth-of-type(2) { animation-name: eggSmash1; }
            div:nth-of-type(3) { animation-name: eggSmash2; }
            div:nth-of-type(4) { animation-name: eggSmash3; }
            div:nth-of-type(5) { animation-name: eggSmash4; }
        }
    }
}

@keyframes eggThrow {
    0% {
        opacity: 1;
        right: calc(75% - 16px);
        transform: translateY(0px) rotate(0deg);
    }
    
    30% {
        transform: translateY(-75px) rotate(720deg);
    }

    33% {
        transform: translateY(-77px) rotate(720deg);
    }

    36% {
        transform: translateY(-75px) rotate(720deg);
    }
    
    66% {
        right: 25%;
        transform: translateY(0px) rotate(1440deg);
        opacity: 1;
    }

    67% {
        opacity: 0;
    }
}

@keyframes eggThrowReverse {
    0% {
        opacity: 1;
        left: calc(75% - 16px);
        transform: translateY(0px) rotate(0deg);
    }
    
    30% {
        transform: translateY(-75px) rotate(720deg);
    }

    33% {
        transform: translateY(-77px) rotate(720deg);
    }

    36% {
        transform: translateY(-75px) rotate(720deg);
    }
    
    66% {
        left: 25%;
        transform: translateY(0px) rotate(1440deg);
        opacity: 1;
    }

    67% {
        opacity: 0;
    }
}

@keyframes eggSmash1 {
    0% {
        opacity: 1;
        transform: translateX(0px) translateY(0px);
    }
    
    100% {
        opacity: 0;
        transform: translateX(-16px) translateY(-16px);
    }
}
@keyframes eggSmash2 {
    0% {
        opacity: 1;
        transform: translateX(0px) translateY(0px);
    }
    
    100% {
        opacity: 0;
        transform: translateX(-16px) translateY(16px);
    }
}
@keyframes eggSmash3 {
    0% {
        opacity: 1;
        transform: translateX(0px) translateY(0px);
    }
    
    100% {
        opacity: 0;
        transform: translateX(16px) translateY(-16px);
    }
}
@keyframes eggSmash4 {
    0% {
        opacity: 1;
        transform: translateX(0px) translateY(0px);
    }
    
    100% {
        opacity: 0;
        transform: translateX(16px) translateY(16px);
    }
}