@import "../../variables.scss";

.quick-view {
  position: fixed;
  top: -54px;
  left: 0;
  width: 100%;
  background-color: $colour-dark;
  transition: top 0.2s ease-out, opacity 0.2s ease-out;
  opacity: 0;
  z-index: 999;
  box-shadow: 1px 1px 25px $colour-highlight;

  .quick-match-wrapper {
    display: flex;
    max-width: 540px;
    margin: 0 auto;
    border-left: solid 1px $colour-secondary;
    border-right: solid 1px $colour-secondary;

    .quick-match-toggler {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      width: 140px;
      overflow-y: auto;
      border-right: solid 1px #ffffffaa;
      max-height: 107px;

      li {
        color: #ffffff;
        list-style-type: none;
        font-size: 12px;
        padding: 5px 10px;
        margin: 0;
        border-bottom: solid 1px #ffffffaa;
        cursor: pointer;

        &.selected {
          background-color: #ffffff66;
        }
      }
    }

    > div {
      flex: 1;
    }
  }

  &.visible {
    top: 54px;
    opacity: 1;
  }

  p {
    margin: 5px 20px;
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;
  }

  .round-match {
    color: #ffffff;
  }

  .round-progress {
    display: block;
    height: 3px;
    background: linear-gradient(
      90deg,
      $colour-primary,
      $colour-highlight,
      $colour-secondary
    );
    transition: width 0.25s linear;
  }

  .loader {
    border-bottom: solid 1px $colour-secondary;
  }

  .small-loader {
    height: 20px;
    position: relative;

    .loader {
      padding: 0;
      min-height: 0;
      border-bottom: solid 1px #ffffff;

      p {
        display: none;
      }
    }
  }
}
