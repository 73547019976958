@import "../../variables.scss";

.help-modal-toggler {
  padding: 5px;
  background: #ffffff;
  color: $colour-secondary;
  margin-left: 10px;
  line-height: 12px;
  border-radius: 100%;
  height: 14px;
  width: 14px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}

.help-modal-mask {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  background-color: $colour-not-quite-so-dark;
  transition: all 0.2s linear;

  &.open {
    opacity: 0.75;
    pointer-events: all;
  }
}

.help-modal {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 75%;
  left: 2.5vw;
  width: 95vw;
  border-radius: 15px;
  padding: 40px;
  box-sizing: border-box;
  z-index: 2001;
  background: $colour-dark;
  box-shadow: 0px 0px 10px 1px $colour-highlight;
  display: flex;
  flex-direction: column;
  text-align: left;
  transition: all 0.2s linear;
  transform: translateY(-50%);
  max-height: 90vh;
  overflow: auto;

  h2 {
    text-align: left;
    width: 100%;
    margin: 5px 0;
    font-size: 22px;
    color: #ffffff;

    span {
      color: $colour-highlight;
    }
  }

  h3 {
    text-align: left;
    width: 100%;
    padding: 5px 0;
    margin: 5px 0;
    font-size: 18px;
    border-bottom: solid 1px #ffffff66;
  }

  p {
    font-size: 14px;
    text-align: left;
    width: 100%;
    font-weight: 300;
    margin: 5px 0 15px 0;
    color: #ffffff;
  }

  .button {
    margin: 10px 0 0 0;
  }

  &.open {
    top: 50%;
    opacity: 1;
    pointer-events: all;
  }
}
