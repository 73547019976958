@import "../../variables";

.dropdown {
  width: 100%;
  display: block;
  padding: 5px 15px;
  border: solid 2px $colour-secondary;
  border-radius: 5px;
  color: $colour-secondary;
  text-decoration: none;
  text-align: left;
  margin: 10px 0;
  background: linear-gradient(
    to bottom right,
    $colour-not-quite-so-dark,
    $colour-even-less-dark
  );
  font-family: "Oswald", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 300;

  &:hover {
    color: $colour-secondary;
    background: $colour-dark;
  }
}
