@import "../../variables.scss";

.page-header {
  margin: 0;
  padding: 20px;
  font-size: 24px;
  border-top: solid 1px #ffffffaa;
  border-bottom: solid 1px #ffffffaa;
  position: relative;
  overflow: hidden;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .help-modal-toggler {
    font-size: 14px;
  }
}
