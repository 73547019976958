@import "../../variables";

.player-container {
  background: $colour-dark;
  color: #ffffff;

  h1 {
    .sponsor {
      display: flex;

      img {
        height: 24px;
        margin-right: 10px;
      }

      span {
        font-size: 16px;
        opacity: 0.6;
        line-height: 24px;
      }
    }

    .avatar {
      display: block;
      height: 64px;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      border-radius: 50%;
    }
  }

  h3 {
    margin: 0 30px;
    padding: 10px 0;
    border-bottom: solid 1px #ffffffaa;
  }

  .score-history {
    width: 100%;

    .recharts-wrapper {
      margin: 0 auto;
    }
  }

  .stats {
    dl {
      display: flex;
      flex-wrap: wrap;
      padding: 0 20px;
      max-height: 200px;
      overflow-y: auto;

      &.tournaments {
        small {
          margin-right: 5px;
          color: #ffffffaa;
        }
      }

      &.seed {
        overflow: visible;

        dt {
          font-size: 24px;
          line-height: 24px;
          font-weight: 700;

          span {
            font-size: 14px;
            font-weight: 300;
            display: block;
          }
        }

        dd {
          .player-actions {
            .button {
              margin: 0;
            }
          }
        }
      }

      dt,
      dd {
        box-sizing: border-box;
        flex: 50%;
        padding: 5px 10px;
        margin: 0;
        border-bottom: solid 1px #ffffff33;
        font-weight: 300;

        &:last-of-type {
          border: none;
        }
      }

      dd {
        justify-content: flex-end;
        align-items: center;
        display: flex;

        .char-icon {
          height: 24px;
          margin-left: 10px;
        }

        .progress {
          background: linear-gradient(
            270deg,
            $colour-primary,
            $colour-highlight,
            $colour-secondary
          );
          width: 150px;
          height: 8px;
          position: relative;

          .overlap {
            position: absolute;
            left: 0;
            top: 0;
            height: 8px;
            background-color: $colour-dark;
            max-width: 149px;
          }
        }
      }
    }
  }

  .character-expertise {
    dt {
      display: flex;
      align-items: center;

      img {
        height: 24px;
        margin-right: 5px;
        display: block;
      }
    }
  }

  .recharts-default-tooltip {
    background-color: $colour-not-quite-so-dark !important;
    border: solid 1px $colour-highlight !important;
    border-radius: 5px;
    display: flex;

    .recharts-tooltip-label {
      color: #ffffff;
      padding-right: 5px;
    }

    .recharts-tooltip-item-list {
      .recharts-tooltip-item {
        padding: 0 !important;

        .recharts-tooltip-item-separator {
          display: none;
        }
      }
    }
  }
}
