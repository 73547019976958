
.fighter.fighter-samus {
    &.fighter-neutral {
        &.strategy-charge_shot {
            animation: neutralFullHop 0.75s infinite;
        }
    }
    &.fighter-edgeguarding {
        &.edgeguarding-remote_bombs {
            animation: none;
        }
    }
}

.fighter.opponent-samus {
    &.fighter-vulnerable {
        animation-delay: 0.4s;
        transition-delay: 0.4s;
    }
}

.fighter-samus-effects {
    &.fighter-one-effects {
        &.fighter-neutral-effects {
            &.strategy-charge_shot-effects,
            &.strategy-campy-effects {
                > div {
                    &:first-of-type {
                        animation: superMissile 0.75s infinite;
                        animation-delay: 0.5s;
                        opacity: 0;
                        background-image: url('/assets/effects/samus/missile_r.png');
                        background-size: auto 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        height: 24px;
                        width: 24px;
                        border-radius: 50%;
                        position: absolute;
                        left: calc(25% - 16px);
                        top: calc(65% - 32px);
                    }
                }
            }
        }

        &.fighter-charging-effects {
            &.strategy-charge_shot-effects {
                > div {
                    transform-origin: 49% 49%;
                    &:first-of-type {
                        transform-origin: center;
                    }
                }
                &.charge-0,
                &.charge-1 {
                    > div:first-of-type {
                        animation: charge 1s;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                        background-color: #ddddff;
                        height: 12px;
                        width: 12px;
                        border-radius: 50%;
                        position: absolute;
                        left: calc(25% + 24px);
                        top: calc(65% - 16px);
                    }
                    
                    > div:nth-of-type(2) {
                        animation: charge 1s;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                        background-color: transparent;
                        border: solid 1px #eeaadd;
                        height: 8px;
                        width: 8px;
                        border-radius: 50%;
                        position: absolute;
                        left: calc(25% + 25px);
                        top: calc(65% - 15px);
                    }

                    > div:nth-of-type(3) {
                        animation: charge 1s;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                        background-color: transparent;
                        border: solid 1px #aa88ee;
                        height: 7px;
                        width: 7px;
                        border-radius: 50%;
                        position: absolute;
                        left: 25%;
                        left: calc(25% + 26px);
                        top: calc(65% - 14px);
                    }
                }
                &.charge-2 {
                    > div:first-of-type {
                        animation: charge 1s;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                        background-color: #ddddff;
                        height: 24px;
                        width: 24px;
                        border-radius: 50%;
                        position: absolute;
                        left: calc(25% + 24px);
                        top: calc(65% - 24px);
                    }
                    
                    > div:nth-of-type(2) {
                        animation: charge 1s;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                        background-color: transparent;
                        border: solid 1px #eeaadd;
                        height: 16px;
                        width: 16px;
                        border-radius: 50%;
                        position: absolute;
                        left: calc(25% + 25px);
                        top: calc(65% - 24px);
                    }

                    > div:nth-of-type(3) {
                        animation: charge 1s;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                        background-color: transparent;
                        border: solid 1px #aa88ee;
                        height: 15px;
                        width: 15px;
                        border-radius: 50%;
                        position: absolute;
                        left: 25%;
                        left: calc(25% + 26px);
                        top: calc(65% - 20px);
                    }
                }
                &.charge-3,
                &.charge-4 {
                    > div:first-of-type {
                        animation: charge 1s;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                        background-color: #ddddff;
                        height: 32px;
                        width: 32px;
                        border-radius: 50%;
                        position: absolute;
                        left: calc(25% + 24px);
                        top: calc(65% - 32px);
                    }
                    
                    > div:nth-of-type(2) {
                        animation: charge 1s;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                        background-color: transparent;
                        border: solid 1px #eeaadd;
                        height: 24px;
                        width: 24px;
                        border-radius: 50%;
                        position: absolute;
                        left: calc(25% + 25px);
                        top: calc(65% - 32px);
                    }

                    > div:nth-of-type(3) {
                        animation: charge 1s;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                        background-color: transparent;
                        border: solid 1px #aa88ee;
                        height: 25px;
                        width: 25px;
                        border-radius: 50%;
                        position: absolute;
                        left: 25%;
                        left: calc(25% + 26px);
                        top: calc(65% - 30px);
                    }
                }
            }
        }

        &.fighter-release_charge_advantage-effects,
        &.fighter-release_charge_punishing-effects {
            &.charge-0,
            &.charge-1 {
                > div:first-of-type {
                    animation: chargeShot 0.5s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    background-color: #ddddff;
                    height: 12px;
                    width: 12px;
                    border-radius: 50%;
                    position: absolute;
                    left: calc(25% + 24px);
                    top: calc(65% - 16px);
                }
                
                > div:nth-of-type(2) {
                    animation: chargeShot 0.5s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    background-color: transparent;
                    border: solid 1px #eeaadd;
                    height: 8px;
                    width: 8px;
                    border-radius: 50%;
                    position: absolute;
                    left: calc(25% + 25px);
                    top: calc(65% - 15px);
                }

                > div:nth-of-type(3) {
                    animation: chargeShot 0.5s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    background-color: transparent;
                    border: solid 1px #aa88ee;
                    height: 7px;
                    width: 7px;
                    border-radius: 50%;
                    position: absolute;
                    left: 25%;
                    left: calc(25% + 26px);
                    top: calc(65% - 14px);
                }
            }
            &.charge-2 {
                > div:first-of-type {
                    animation: chargeShot 0.5s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    background-color: #ddddff;
                    height: 24px;
                    width: 24px;
                    border-radius: 50%;
                    position: absolute;
                    left: calc(25% + 24px);
                    top: calc(65% - 24px);
                }
                
                > div:nth-of-type(2) {
                    animation: chargeShot 0.5s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    background-color: transparent;
                    border: solid 1px #eeaadd;
                    height: 16px;
                    width: 16px;
                    border-radius: 50%;
                    position: absolute;
                    left: calc(25% + 25px);
                    top: calc(65% - 24px);
                }

                > div:nth-of-type(3) {
                    animation: chargeShot 0.5s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    background-color: transparent;
                    border: solid 1px #aa88ee;
                    height: 15px;
                    width: 15px;
                    border-radius: 50%;
                    position: absolute;
                    left: 25%;
                    left: calc(25% + 26px);
                    top: calc(65% - 20px);
                }
            }
            &.charge-3,
            &.charge-4 {
                > div:first-of-type {
                    animation: chargeShot 0.5s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    background-color: #ddddff;
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                    position: absolute;
                    left: calc(25% + 24px);
                    top: calc(65% - 32px);
                }
                
                > div:nth-of-type(2) {
                    animation: chargeShot 0.5s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    background-color: transparent;
                    border: solid 1px #eeaadd;
                    height: 24px;
                    width: 24px;
                    border-radius: 50%;
                    position: absolute;
                    left: calc(25% + 25px);
                    top: calc(65% - 32px);
                }

                > div:nth-of-type(3) {
                    animation: chargeShot 0.5s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    background-color: transparent;
                    border: solid 1px #aa88ee;
                    height: 25px;
                    width: 25px;
                    border-radius: 50%;
                    position: absolute;
                    left: 25%;
                    left: calc(25% + 26px);
                    top: calc(65% - 30px);
                }
            }
        }
    }

    &.fighter-two-effects {
        &.fighter-neutral-effects {
            &.strategy-charge_shot-effects,
            &.strategy-campy-effects {
                > div {
                    &:first-of-type {
                        animation: superMissileReverse 0.75s infinite;
                        animation-delay: 0.5s;
                        opacity: 0;
                        background-image: url('/assets/effects/samus/missile.png');
                        background-size: auto 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        height: 24px;
                        width: 24px;
                        border-radius: 50%;
                        position: absolute;
                        right: calc(25% - 16px);
                        top: calc(65% - 32px);
                    }
                }
            }
        }
        
        &.fighter-charging-effects {
            &.strategy-charge_shot-effects {
                > div {
                    transform-origin: 49% 49%;
                    &:first-of-type {
                        transform-origin: center;
                    }
                }

                &.charge-0,
                &.charge-1 {
                    > div:first-of-type {
                        animation: charge 1s;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                        background-color: #ddddff;
                        height: 12px;
                        width: 12px;
                        border-radius: 50%;
                        position: absolute;
                        right: calc(25% + 24px);
                        top: calc(65% - 16px);
                    }
                    
                    > div:nth-of-type(2) {
                        animation: charge 1s;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                        background-color: transparent;
                        border: solid 1px #eeaadd;
                        height: 8px;
                        width: 8px;
                        border-radius: 50%;
                        position: absolute;
                        right: calc(25% + 25px);
                        top: calc(65% - 15px);
                    }

                    > div:nth-of-type(3) {
                        animation: charge 1s;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                        background-color: transparent;
                        border: solid 1px #aa88ee;
                        height: 7px;
                        width: 7px;
                        border-radius: 50%;
                        position: absolute;
                        right: calc(25% + 26px);
                        top: calc(65% - 14px);
                    }
                }
                &.charge-2 {
                    > div:first-of-type {
                        animation: charge 1s;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                        background-color: #ddddff;
                        height: 24px;
                        width: 24px;
                        border-radius: 50%;
                        position: absolute;
                        right: calc(25% + 24px);
                        top: calc(65% - 24px);
                    }
                    
                    > div:nth-of-type(2) {
                        animation: charge 1s;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                        background-color: transparent;
                        border: solid 1px #eeaadd;
                        height: 16px;
                        width: 16px;
                        border-radius: 50%;
                        position: absolute;
                        right: calc(25% + 25px);
                        top: calc(65% - 24px);
                    }

                    > div:nth-of-type(3) {
                        animation: charge 1s;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                        background-color: transparent;
                        border: solid 1px #aa88ee;
                        height: 15px;
                        width: 15px;
                        border-radius: 50%;
                        position: absolute;
                        right: calc(25% + 26px);
                        top: calc(65% - 20px);
                    }
                }
                &.charge-3,
                &.charge-4 {
                    > div:first-of-type {
                        animation: charge 1s;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                        background-color: #ddddff;
                        height: 32px;
                        width: 32px;
                        border-radius: 50%;
                        position: absolute;
                        right: calc(25% + 24px);
                        top: calc(65% - 32px);
                    }
                    
                    > div:nth-of-type(2) {
                        animation: charge 1s;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                        background-color: transparent;
                        border: solid 1px #eeaadd;
                        height: 24px;
                        width: 24px;
                        border-radius: 50%;
                        position: absolute;
                        right: calc(25% + 25px);
                        top: calc(65% - 32px);
                    }

                    > div:nth-of-type(3) {
                        animation: charge 1s;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                        background-color: transparent;
                        border: solid 1px #aa88ee;
                        height: 25px;
                        width: 25px;
                        border-radius: 50%;
                        position: absolute;
                        right: calc(25% + 26px);
                        top: calc(65% - 30px);
                    }
                }
            }
        }

        &.fighter-release_charge_advantage-effects,
        &.fighter-release_charge_punishing-effects {
            &.charge-0,
            &.charge-1 {
                > div:first-of-type {
                    animation: chargeShotReverse 0.5s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    background-color: #ddddff;
                    height: 12px;
                    width: 12px;
                    border-radius: 50%;
                    position: absolute;
                    right: calc(25% + 24px);
                    top: calc(65% - 16px);
                }
                
                > div:nth-of-type(2) {
                    animation: chargeShotReverse 0.5s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    background-color: transparent;
                    border: solid 1px #eeaadd;
                    height: 8px;
                    width: 8px;
                    border-radius: 50%;
                    position: absolute;
                    right: calc(25% + 25px);
                    top: calc(65% - 15px);
                }

                > div:nth-of-type(3) {
                    animation: chargeShotReverse 0.5s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    background-color: transparent;
                    border: solid 1px #aa88ee;
                    height: 7px;
                    width: 7px;
                    border-radius: 50%;
                    position: absolute;
                    right: 25%;
                    right: calc(25% + 26px);
                    top: calc(65% - 14px);
                }
            }
            &.charge-2 {
                > div:first-of-type {
                    animation: chargeShotReverse 0.5s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    background-color: #ddddff;
                    height: 24px;
                    width: 24px;
                    border-radius: 50%;
                    position: absolute;
                    right: calc(25% + 24px);
                    top: calc(65% - 24px);
                }
                
                > div:nth-of-type(2) {
                    animation: chargeShotReverse 0.5s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    background-color: transparent;
                    border: solid 1px #eeaadd;
                    height: 16px;
                    width: 16px;
                    border-radius: 50%;
                    position: absolute;
                    right: calc(25% + 25px);
                    top: calc(65% - 24px);
                }

                > div:nth-of-type(3) {
                    animation: chargeShotReverse 0.5s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    background-color: transparent;
                    border: solid 1px #aa88ee;
                    height: 15px;
                    width: 15px;
                    border-radius: 50%;
                    position: absolute;
                    right: 25%;
                    right: calc(25% + 26px);
                    top: calc(65% - 20px);
                }
            }
            &.charge-3,
            &.charge-4 {
                > div:first-of-type {
                    animation: chargeShotReverse 0.5s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    background-color: #ddddff;
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                    position: absolute;
                    right: calc(25% + 24px);
                    top: calc(65% - 32px);
                }
                
                > div:nth-of-type(2) {
                    animation: chargeShotReverse 0.5s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    background-color: transparent;
                    border: solid 1px #eeaadd;
                    height: 24px;
                    width: 24px;
                    border-radius: 50%;
                    position: absolute;
                    right: calc(25% + 25px);
                    top: calc(65% - 32px);
                }

                > div:nth-of-type(3) {
                    animation: chargeShotReverse 0.5s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    background-color: transparent;
                    border: solid 1px #aa88ee;
                    height: 25px;
                    width: 25px;
                    border-radius: 50%;
                    position: absolute;
                    right: 25%;
                    right: calc(25% + 26px);
                    top: calc(65% - 30px);
                }
            }
        }
    }
}

@keyframes charge {
    0% {
        transform: scale(0.5) rotate(0deg);
    } 

    100% {
        transform: scale(1) rotate(720deg);
    }
}

@keyframes chargeShot {
    0% {
        left: calc(25% + 24px);
    }

    90% {
        opacity: 1;
        left: calc(75% - 32px);
        transform: scale(1);
    }

    100% {
        opacity: 0;
        left: calc(75% - 32px);
        transform: scale(2);
    }
}

@keyframes chargeShotReverse {
    0% {
        right: calc(25% + 24px);
    }

    90% {
        opacity: 1;
        right: calc(75% - 32px);
        transform: scale(1);
    }

    100% {
        opacity: 0;
        right: calc(75% - 32px);
        transform: scale(2);
    }
}

@keyframes superMissile {
    0% {
        left: calc(25% + 16px);
        opacity: 1;
    }

    30% {
        left: calc(25% + 48px);
    }


    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        left: calc(75% - 32px);
    }
}

@keyframes superMissileReverse {
    0% {
        right: calc(25% + 16px);
        opacity: 1;
    }

    30% {
        right: calc(25% + 48px);
    }


    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        right: calc(75% - 32px);
    }
}