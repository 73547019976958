.coming-soon {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
        color: #ffffff;
    }
}

.brackets {
    display: flex;
    justify-content: space-between;
    max-width: 768px;
    margin: 0 auto;

    div {
        width: 100px;
        color: #ffffff;

        p {
            font-weight: 300;
            font-size: 14px;
            margin: 2px 0;
            text-align: center;
        }
    }
}