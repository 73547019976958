.fighter.fighter-ike {
    &.fighter-punishing {
        transition-duration: 0.2s;   
    }

    &.fighter-recovering {
        z-index: 2;

        &.fighter-one {
            animation: aether 0.75s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            left: 25%;
            transition: none;
        }

        &.fighter-two {
            animation: aetherReverse 0.75s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            right: 25%;
            transition: none;
        }
    }
}

.fighter.opponent-ike {
    &.fighter-vulnerable {
        animation-delay: 0.2s;
        transition-delay: 0.2s;
    }
}


.fighter-ike-effects {
    &.fighter-one-effects {
        &.fighter-punishing-effects {
            div:first-of-type {
                opacity: 0;
                height: 32px;
                width: 32px;
                background-image: url('/assets/effects/ike/ragnell.png');
                background-size: 32px;
                background-position: bottom 4px left;
                background-repeat: no-repeat;
                transform-origin: 0 center;
                left: calc(75% - 16px);
                top: calc(65% - 32px);
                position: absolute;
                animation: swordSmash 0.5s;
                animation-delay: 0.2s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;

                &:after {
                    display: block;
                    background: linear-gradient(0deg, rgba(242, 205, 112, 0.9) 0%, rgba(242,205,112,0) 100%);
                    height: 24px;
                    width: 16px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    content: ' ';
                    animation: fadeOut 0.5s;
                    animation-delay: 0.2s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                }
            }
        }

        &.fighter-recovering-effects {
            div:first-of-type {
                height: 32px;
                width: 32px;
                background-image: url('/assets/effects/ike/ragnell.png');
                background-size: 32px;
                background-position: bottom 4px left;
                background-repeat: no-repeat;
                transform-origin: 0 center;
                left: calc(25% + 16px);
                position: absolute;
                animation: aether 0.75s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                
                &:after {
                    display: block;
                    background: linear-gradient(0deg, rgba(242, 205, 112, 0.9) 0%, rgba(242,205,112,0) 100%);
                    height: 24px;
                    width: 16px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    content: ' ';
                    animation: fadeOut 1.25s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                }
            }
        }
    }
    
    &.fighter-two-effects {
        &.fighter-punishing-effects {
            div:first-of-type {
                opacity: 0;
                height: 32px;
                width: 32px;
                background-image: url('/assets/effects/ike/ragnell.png');
                background-size: 32px;
                background-position: bottom 4px left;
                background-repeat: no-repeat;
                transform-origin: 100% center;
                right: calc(75% - 16px);
                top: calc(65% - 32px);
                position: absolute;
                animation: swordSmashReverse 0.5s;
                animation-delay: 0.2s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;

                &:after {
                    display: block;
                    background: linear-gradient(0deg, rgba(242, 205, 112, 0.9) 0%, rgba(242,205,112,0) 100%);
                    height: 24px;
                    width: 16px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: ' ';
                    animation: fadeOut 0.5s;
                    animation-delay: 0.2s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                }
            }
        }

        &.fighter-recovering-effects {
            div:first-of-type {
                height: 32px;
                width: 32px;
                background-image: url('/assets/effects/ike/ragnell.png');
                background-size: 32px;
                background-position: bottom 4px right;
                background-repeat: no-repeat;
                transform-origin: 100% center;
                right: calc(25% + 16px);
                position: absolute;
                animation: aetherReverse 0.75s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                
                &:after {
                    display: block;
                    background: linear-gradient(0deg, rgba(242, 205, 112, 0.9) 0%, rgba(242,205,112,0) 100%);
                    height: 24px;
                    width: 16px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: ' ';
                    animation: fadeOut 1.25s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                }
            }
        }
    }
}

@keyframes aether {
    0% {
        top: 100%;
        transform: rotate(0deg);
    }

    80% {
        top: 30%;
        transform: rotate(3600deg);
    }

    90% {
        top: calc(65% - 32px);
        transform: rotate(3600deg);
    }
    
    100% {
        top: calc(65% - 32px);
        transform: rotate(3600deg);
    }   
}

@keyframes aetherReverse {
    0% {
        top: 100%;
        transform: rotate(0deg);
    }

    80% {
        top: 30%;
        transform: rotate(-3600deg);
    }

    90% {
        top: calc(65% - 32px);
        transform: rotate(-3600deg);
    }
    
    100% {
        top: calc(65% - 32px);
        transform: rotate(-3600deg);
    }   
}

@keyframes swordSmash {
    0% {
        opacity: 1;
        transform: rotate(-120deg);
    }

    100% {
        opacity: 1;
        transform: rotate(0deg);
    }
}

@keyframes swordSmashReverse {
    0% {
        opacity: 1;
        transform: rotate(120deg);
    }

    100% {
        opacity: 1;
        transform: rotate(0deg);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}