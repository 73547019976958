@import "../../variables.scss";

.sponsors-container {
    .sponsor {
        color: #ffffff;
        display: flex;
        padding: 20px;
        border-bottom: solid 1px #ffffffaa;

        > img {
            width: 64px;
            display: block;
            align-self: flex-start;
            margin-right: 20px;
        }

        .sponsor-details {
            flex: 1;
            
            h2 {
                margin: 0;
                text-align: center;
                font-size: 20px;
            }

            p {
                font-size: 14px;
            }

            dl {
                display: flex;
                flex-wrap: wrap;
                font-size: 14px;

                dt {
                    flex: 35%;
                }

                dd {
                    flex: 65%;
                }

                dt, dd {
                    box-sizing: border-box;
                    padding: 5px 10px;
                    margin: 0;
                    border-bottom: solid 1px #ffffff33;
                    font-weight: 300;

                    &:last-of-type {
                        border: none;
                    }

                    a {
                        color: #ffffff;
                        text-decoration: none;

                        &.human {
                            color: $colour-secondary;
                            font-weight: 700;
                        }

                        img {
                            height: 12px;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
}