@import "../../variables.scss";

.wiki {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
    padding: 10px;
    box-sizing: border-box;
    color: #ffffff;
    position: relative;

    aside {
        position: fixed;
        width: 220px;
        top: 50px;
        height: 100%;
        border-right: solid 1px #ffffffaa;
        transition: all 0.2s linear;
        z-index: 2;
        left: 0px;
        height: calc(100vh - 75px);
        padding-bottom: 20px;
        overflow-y: auto;

        ul {
            padding: 0 20px;
        }
        
        > ul {
            padding-bottom: 20px;
        }

        li {
            cursor: pointer;
            text-decoration: underline;
            font-weight: 300;
            color: $colour-highlight;
        }

        .button {
            position: sticky;
            top: 40px;
            width: 40px;
            font-size: 12px;
            transform: rotate(-90deg) translateY(175px);
            display: none;
        }
    }

    article {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        padding: 20px 20px 50px 20px;
        box-sizing: border-box;
        transition: all 0.2s linear;

        img {
            display: block;
            max-width: 256px;
            margin: 0 auto;

            &.icon {
                display: inline;
                width: 24px;
                margin: 0 10px;
            }
        }

        p, li {
            font-weight: 300;
        }
        
    }


    @media screen and (max-width: 1180px) {
        aside {
            background-color: $colour-even-less-dark;
            padding: 0 20px;
            left: -200px;

            .button {
                display: block;
            }

            &.open {
                left: 0;
            }
            
            > ul {
                margin-top: -30px;
            }
        }

        article {
            width: calc(100% - 60px);
            left: auto;
            right: 0px;
        }
    }
}