@import "../../variables.scss";

.switcher {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    > span {
        flex: 50%;
        box-sizing: border-box;
        padding: 10px 15px;
        border-top: solid 1px #ffffff;
        border-bottom: solid 1px #ffffff;
        font-weight: 300;
        color: #ffffff;

        &.active {
            background-color: $colour-secondary;
            font-weight: bold;
            border-left: solid 1px $colour-highlight;
            border-right: solid 1px $colour-highlight;
        }
    }

    > a {
        flex: 50%;
        box-sizing: border-box;
        padding: 10px 15px;
        color: #ffffff66;
        text-decoration: none;
        border-bottom: solid 1px #ffffff;

        > span {
            width: 100%;
            padding: 0;
        }
    }
}