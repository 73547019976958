@import "../../variables.scss";

.follow-management {
    position: relative;

    .button {
        width: 72px !important;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 14px;
        padding: 5px 10px;
    }

    .follow-options {
        display: block;
        z-index: 2;
        position: absolute;
        background-color: $colour-even-less-dark;
        width: 120px;
        right: 0;
        top: 35px;
        border: solid 1px $colour-highlight;
        border-radius: 5px;
        border-top-right-radius: 0;

        p {
            margin: 0;
            padding: 5px;
            border-bottom: solid 1px #ffffff33;

            &:last-of-type {
                border: none;
            }
        }
    }
}