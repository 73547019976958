@import "../../variables.scss";

.video-thumbnail {
  color: #ffffff;
  padding: 30px;
  border-bottom: solid 1px #ffffff;

  .video-thumbnail-image {
    height: 150px;
    border: solid 1px #cdcdcd;
    background-color: rgba(255, 255, 255, 0.5);
    overflow: hidden;
    position: relative;

    img {
      position: absolute;
      height: 120%;
      transform-origin: 0 0;
    }

    .background {
      width: 120%;
      top: 50%;
      left: 50%;
      height: auto;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  p,
  .stream-details {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 0 0;
    line-height: 24px;

    p {
      margin-top: 0;
    }

    > a {
      display: flex;
      color: #ffffff;
      text-decoration: none;
    }

    .avatar {
      display: block;
      height: 24px;
      margin-right: 5px;
      border-radius: 50%;
    }

    .quality {
      position: relative;

      .template {
        position: relative;
        z-index: 2;
        width: 120px;
        height: 24px;
        display: block;
        background-image: url(/assets/rating.png);
        background-size: 100%;
        background-repeat: no-repeat;

        &.light {
          background-image: url(/assets/rating_l.png);
        }
      }

      .quality-rating {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        display: block;
        height: 24px;
        background-color: goldenrod;
      }
    }
  }
}
