@import "../../variables.scss";

.text-input {
    padding: 5px;
    width: 100%;
    background-color: $colour-not-quite-so-dark;
    border: solid 1px $colour-secondary;
    outline: none;
    color: $colour-secondary;
    font-size: 16px;
    border-radius: 5px;
    box-sizing: border-box;
    margin: 5px 0;
    font-family: 'Oswald', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 300;
    letter-spacing: 0.5px;
}