@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import "./variables";

body {
  margin: 0;
  font-family: "Oswald", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  background-color: $colour-dark;
  overscroll-behavior: contain;
}

html,
body {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  max-width: 540px;
  margin: 0 auto;
  border-left: solid 1px $colour-secondary;
  border-right: solid 1px $colour-secondary;

  @media screen and (max-width: 540px) {
    border: none;
  }
}

main {
  background-color: $colour-dark;
  transition: padding 0.4s linear;
  padding-bottom: 45px;
  padding-top: 54px;
  min-height: calc(100vh - 45px - 54px);

  &.no-header {
    padding: 0;
  }

  &.tourneys-in-progress {
    padding-top: 165px;
    min-height: calc(100vh - 45px - 144px);

    .wiki aside {
      padding-top: 110px;
      > ul {
        padding-bottom: 110px;
      }
    }
  }
}
