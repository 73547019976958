@import "../../variables";

nav {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: $colour-even-less-dark;
  width: 100vw;
  z-index: 1000;

  &:before {
    position: absolute;
    top: -1px;
    height: 1px;
    width: 100%;
    background: linear-gradient(to left, $colour-secondary, $colour-highlight);
    display: block;
    content: " ";
  }

  ul {
    display: flex;
    margin: 0 auto;
    padding: 0;
    justify-content: center;
    max-width: 768px;

    a {
      color: #ffffff;
      text-decoration: none;

      &:last-of-type {
        li {
          border-right: none;
        }
      }

      li {
        padding: 8px;
        list-style-type: none;
        display: flex;
        line-height: 25px;
        border-right: solid 1px $colour-secondary-darker;
        transition: width 0.2s linear, background-color 0.2s linear;

        span {
          opacity: 0;
          position: absolute;
          left: 0px;
          width: 100%;
          bottom: 100%;
          background-color: $colour-secondary-darker;
          padding: 2px 0;
          box-shadow: 0px -5px 5px #00000099;
          text-align: center;
          font-size: 14px;
          pointer-events: none;
        }

        img {
          height: 22px;
          transition: all 0.2s linear;
        }
      }

      &:focus,
      &:hover {
        li {
          background-color: $colour-secondary-darker;

          span {
            z-index: 2;
            opacity: 1;
            background-color: $colour-secondary-darker;
          }
        }
      }

      &.active {
        // width: 100%;

        li {
          background-color: $colour-secondary;
          text-shadow: 0px 0px 4px #000000;

          span {
            z-index: 1;
            background-color: $colour-secondary;
            // opacity: 0;
            // animation: quickShow 3s;
            animation-fill-mode: forwards;
            animation-iteration-count: 1;
          }
        }

        img {
          fill: $colour-not-quite-so-dark;
          // margin-right: 10px;
        }
      }
    }
  }
}

@keyframes quickShow {
  0% {
    opacity: 0.5;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

header {
  top: 0;
  left: 0;
  background: linear-gradient(
    to bottom left,
    $colour-not-quite-so-dark,
    $colour-even-less-dark
  );
  color: #ffffff;
  width: 100vw;
  z-index: 1000;
  position: fixed;

  &:after {
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 100%;
    background: linear-gradient(to right, $colour-secondary, $colour-highlight);
    display: block;
    content: " ";
  }

  .logo-container {
    display: flex;

    .logo {
      height: 24px;
      display: block;
    }

    span {
      font-weight: 700;
      background: linear-gradient($colour-secondary, $colour-highlight);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 18px;
      margin: 0 2px;
      line-height: 24px;
    }
  }

  ul {
    display: flex;
    margin: 0 auto;
    padding: 15px;
    justify-content: space-between;
    max-width: 768px;
    position: relative;

    li {
      list-style-type: none;
      padding: 0 5px;

      .time {
        padding-right: 60px;
        display: flex;
        flex-direction: column;
        text-align: right;
        margin-top: -6px;
        margin-bottom: -6px;
        font-size: 14px;
        line-height: 18px;

        span {
          opacity: 0.7;
        }
      }

      button {
        position: absolute;
        margin-left: 20px;
        padding: 20px 15px;
        top: 0;
        right: 0;
        border-radius: 0px;
        border: none;
        font-family: "Oswald", sans-serif;
        text-transform: uppercase;
        color: #ffffff;
        line-height: 14px;
        font-size: 18px;
        background: linear-gradient(
          to bottom right,
          $colour-secondary 25%,
          $colour-highlight
        );
        transition: opacity 0.2s linear;

        &:disabled {
          opacity: 0.25;
        }
      }
    }
  }
}
