.fighter.fighter-squirtle,
.fighter.fighter-ivysaur,
.fighter.fighter-charizard {
    &.fighter-switching_form {
        transition: none;
        animation: switchPokemon 0.5s;
        animation-delay: 0.7s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        opacity: 0;
    }
}

.fighter.opponent-ivysaur {
    
}

.fighter-squirtle-effects, 
.fighter-ivysaur-effects, 
.fighter-charizard-effects {
    &.fighter-one-effects {
        div:nth-of-type(5) {
            position: absolute;
            top: 20px;
            left: 20%;
            background-image: url("../../../assets/effects/pokemon_trainer/pokemon_trainer.png");
            height: 16px;
            width: 24px;
            background-size: 16px;
            background-position: center;
            background-repeat: no-repeat;
            border-bottom: solid 2px #ffffffaa;
        }

        &.fighter-switching_form-effects {
            div:first-of-type {
                position: absolute;
                top: 20px;
                left: 20%;
                background-image: url("../../../assets/effects/pokemon_trainer/pokeball.png");
                height: 16px;
                width: 24px;
                background-size: 16px;
                background-position: center;
                background-repeat: no-repeat;
                animation: pokeball 0.7s;
                animation-iteration-count: 1;
            }
        }
    }
    
    &.fighter-two-effects {
        div:nth-of-type(5) {
            position: absolute;
            top: 20px;
            right: 20%;
            background-image: url("../../../assets/effects/pokemon_trainer/pokemon_trainer.png");
            height: 16px;
            width: 24px;
            background-size: 16px;
            background-position: center;
            background-repeat: no-repeat;
            border-bottom: solid 2px #ffffffaa;
        }

        &.fighter-switching_form-effects {
            div:first-of-type {
                position: absolute;
                top: 20px;
                right: 20%;
                background-image: url("../../../assets/effects/pokemon_trainer/pokeball.png");
                height: 16px;
                width: 24px;
                background-size: 16px;
                background-position: center;
                background-repeat: no-repeat;
                animation: pokeballReverse 0.7s;
                animation-iteration-count: 1;
            }
        }
    }
}

@keyframes switchPokemon {
    0% {
        opacity: 0;
        transform: scale(0.01);
        filter: brightness(5);
    }
    
    50% {
        opacity: 1;
        transform: scale(1);
        filter: brightness(5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
        filter: brightness(1);
    }
}

@keyframes pokeball {
    0% {
        transform: translateX(0px) translateY(0px) scale(0.01) rotate(0deg);
    }
    
    30% {
        transform: translateX(5px) translateY(-10px) scale(0.2) rotate(3600deg);
    }

    90% {
        filter: brightness(1);
    }
    
    100% {
        transform: translateX(15px) translateY(80px) scale(1) rotate(10800deg);
        filter: brightness(5);
    }
}

@keyframes pokeballReverse {
    0% {
        transform: translateX(0px) translateY(0px) scale(0.01) rotate(0deg);
    }
    
    30% {
        transform: translateX(-5px) translateY(-10px) scale(0.2) rotate(-3600deg);
    }

    90% {
        filter: brightness(1);
    }
    
    100% {
        transform: translateX(-15px) translateY(80px) scale(1) rotate(-10800deg);
        filter: brightness(5);
    }
}