@import "../../variables.scss";

.tiers-container {
  img {
    height: 24px;
  }

  th {
    font-size: 12px;
  }

  table {
    width: 100%;
    background-color: $colour-even-less-dark;
  }

  tr:nth-of-type(2n) {
    background-color: lighten($colour-even-less-dark, 2.5%);
  }

  th,
  td {
    color: #ffffff;

    &.section-left {
      border-left: solid 1px transparentize($colour-secondary-darker, 0.5);
    }

    &.section-right {
      border-right: solid 1px transparentize($colour-secondary-darker, 0.5);
    }

    &.fighter-type {
      align-items: center;
      justify-content: center;

      img {
        display: block;
        padding: 1px;
        height: 20px;
      }
    }

    table {
      background: transparent !important;

      tr {
        background: transparent !important;

        td {
          border-right: solid 1px $colour-highlight;
          padding: 0;
          width: 25px !important;

          &:last-of-type {
            border-right: none;
          }
        }

        &:nth-of-type(2) {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }

  td {
    width: 10px;
    text-align: center;
    padding: 0 8px;
    font-size: 12px;
    border-bottom: solid 1px $colour-secondary-darker;
    margin: 0;
    color: #ffffff;

    &:first-of-type {
      width: 105px;

      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        color: #ffffff;
        text-decoration: none;

        img {
          margin-right: 10px;
        }
      }
    }

    .fighter-name {
      flex: 1;
    }

    .position-change,
    .balance-change {
      display: block;
      width: 10px;
      position: relative;
      margin-left: 10px;

      &.improvement,
      &.fall {
        &:after {
          content: " ";
          position: absolute;
          transform: translateY(-50%);
          top: 50%;
          width: 0;
          height: 0;
          border-style: solid;
        }
      }

      &.improvement {
        &:after {
          border-width: 0 4px 8px 4px;
          border-color: transparent transparent $colour-secondary transparent;
        }
      }
      &.fall {
        &:after {
          border-width: 8px 4px 0 4px;
          border-color: $colour-primary transparent transparent transparent;
        }
      }
    }

    .balance-change {
      margin: 0;

      &:after {
        left: 0;
      }
    }

    .position-change {
      text-align: right;
      padding-right: 6px;
      color: #00000077;
      margin-left: 2px;

      &:after {
        right: -5px;
      }
    }
  }

  .tier-boundary {
    td {
      background: $colour-highlight;
      color: #00000077;
    }
  }
}
