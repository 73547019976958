@import "../../variables";

.top-players-container {
    background: $colour-dark;
    color: #ffffff;
    
    .patience-message {
        margin: 60px 40px;
        text-align: center;
        color: #ffffff;
        font-weight: lighter;
    }

    .filters {
        padding: 10px 20px;
    }

    .top-players {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;

        &:first-of-type {
            > li {
                border-top: none;
            }
        }

        li {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }
    
        > ul {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            padding: 0;

            > li {
                font-weight: 300;
                flex: 100%;
                padding: 10px 15px;
                background-color: $colour-even-less-dark;
                border-bottom: solid 1px #ffffff66;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;

                &.has-sponsor {
                    height: 36px;
                }

                a {
                    flex: 100%;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    color: #ffffff;
                    text-decoration: none;
                    display: flex;

                    img {
                        height: 14px;
                        margin-left: 10px;
                    }
                    
                    > div {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .sponsor {
                            display: flex;
                            align-items: center;

                            img {
                                margin: 0 5px 0 0;
                                height: 24px;
                            }

                            span {
                                opacity: 0.6;
                                font-size: 12px;
                            }
                        }
                    }
                    
                    strong {
                        display: flex;
                        align-items: center;
                    }
                }
                

                .rank {
                    display: block;
                    flex: 56px;
                }

                img {
                    display: block;
                    height: 24px;
                    margin-right: 10px;
                }

                .button {
                    margin-left: 10px;
                    line-height: 14px;
                    padding: 5px 10px;
                    transition: none;

                    &.primary {
                        padding: 7px 17px;
                    }
                }
            }
        }
    }
}