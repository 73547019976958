.fighter.fighter-diddy_kong {
    &.fighter-advantage {
        animation: neutralIdle 1s infinite;

        &.fighter-one {
            left: calc(50% + 32px);
        }
        
        &.fighter-two {
            right: calc(50% + 32px);
        }
    }
}

.fighter.opponent-diddy_kong {
    &.fighter-disadvantage {
        &.fighter-one {
            animation: slip 0.25s;
            animation-delay: 0.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        } 
        
        &.fighter-two {
            animation: slipReverse 0.25s;
            animation-delay: 0.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
    }
}

.fighter-diddy_kong-effects {
    &.fighter-one-effects {
        &.fighter-advantage-effects {
            div:first-of-type {
                animation: bananaThrow 0.5s;
                animation-iteration-count: 1;
                height: 24px;
                width: 24px;
                position: absolute;
                top: calc(65% - 24px);
                background-image: url('/assets/effects/diddy_kong/banana.png');
                background-size: 24px;
                background-repeat: no-repeat;
                opacity: 0;
            }
        }
    }
    
    &.fighter-two-effects {
        &.fighter-advantage-effects {
            div:first-of-type {
                animation: bananaThrowReverse 0.5s;
                animation-iteration-count: 1;
                height: 24px;
                width: 24px;
                position: absolute;
                top: calc(65% - 24px);
                background-image: url('/assets/effects/diddy_kong/banana.png');
                background-size: 24px;
                background-repeat: no-repeat;
                opacity: 0;
            }
        }
    }
}

@keyframes bananaThrow {
    0% {
        left: 25%;
        transform: translateY(0px) rotate(0deg);
        opacity: 1;
    }

    50% {
        transform: translateY(-10px) rotate(540deg);
    }

    100% {
        left: calc(75% - 32px);
        transform: translateY(0px) rotate(1080deg);
        opacity: 1;
    }
}

@keyframes slip {
    0% {
        transform: translateY(0px) translateX(0px) rotate(0deg);
    }

    50% {
        transform: translateY(-15px) translateX(15px) rotate(45deg);
    }

    100% {
        transform: translateY(0px) translateX(15px) rotate(90deg);
    }
}

@keyframes bananaThrowReverse {
    0% {
        right: 25%;
        transform: translateY(0px) rotate(0deg);
        opacity: 1;
    }

    50% {
        transform: translateY(-10px) rotate(540deg);
    }

    100% {
        right: calc(75% - 32px);
        transform: translateY(0px) rotate(1080deg);
        opacity: 1;
    }
}

@keyframes slipReverse {
    0% {
        transform: translateY(0px) translateX(0px) rotate(0deg);
    }

    50% {
        transform: translateY(-15px) translateX(-15px) rotate(-45deg);
    }

    100% {
        transform: translateY(0px) translateX(-15px) rotate(-90deg);
    }
}