@import "../../variables.scss";

.player-actions {
  .tournaments {
    margin: 20px 30px;

    .tournament {
      display: flex;
      flex-wrap: wrap;
      color: #ffffff;
      border-bottom: solid 1px #ffffff33;
      align-items: center;
      padding: 10px 0;
      margin: 0;

      &:last-of-type {
        border: none;
      }

      h3,
      .button {
        font-size: 14px;
        box-sizing: border-box;
        flex: 50%;
        padding: 5px 0;
        margin: 0;
      }

      small {
        width: 100%;
        display: block;
        font-weight: 300;
        margin: 5px 0 0 0;
        text-align: center;
        color: #ffffff99;
      }
    }
  }

  .stat-points {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: #ffffff;
    padding: 0 20px;

    p {
      flex: 100%;
      font-size: 13px;
      font-weight: 300;
      text-align: right;
      margin: 0;
    }

    dt,
    dd {
      box-sizing: border-box;
      padding: 5px 0;
      margin: 0;
      border-bottom: solid 1px #ffffff33;
      font-weight: 300;
      display: flex;
      align-items: center;

      &:last-of-type {
        border: none;
      }
    }

    dt {
      flex: 25%;
    }

    dd {
      flex: 75%;
      justify-content: flex-end;
    }
  }

  .character-select-screen {
    margin: 20px 0;
  }

  .friendlies,
  .smash-tube {
    padding: 0 30px 30px 30px;

    .action {
      display: flex;
      flex-direction: column;

      select {
        font-size: 14px;
        padding: 5px;
      }

      .button {
        width: 200px;
        display: block;
        margin: 10px auto;
        font-size: 14px;
      }
    }

    .details {
      display: flex;
      justify-content: space-around;

      span {
        color: #ffffff;
        font-weight: lighter;
      }
    }

    p {
      color: #ffffff;
      font-weight: lighter;
    }
  }

  .other-actions {
    padding: 30px 0;
    border-top: solid 1px #ffffff33;

    .button {
      width: 200px;
      display: block;
      margin: 0 auto;
    }
  }
}

.actions-table {
  color: #ffffff;
  max-height: 200px;
  overflow-y: scroll;
  display: block;
  border-spacing: 0px;
  border-collapse: separate;
  margin: 10px auto;
  width: 100%;

  tr {
    td {
      border-bottom: solid 1px #ffffff66;
      cursor: pointer;
      width: 100%;
      padding: 2px 5px;

      img {
        height: 24px;
      }
    }

    &:last-of-type {
      td {
        border-bottom: none;
      }
    }
  }

  .selected {
    td {
      background-color: #ffffff33;
    }
  }
}
