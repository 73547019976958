
.round-match {
    padding: 5px;
    border-top: solid 1px #dedede;
    border-bottom: solid 1px hsl(0, 0%, 87%);

    > div {
        padding: 0 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
            height: 16px;
            display: block;
            padding: 0 10px;
        }

        span {
            display: flex;
            align-items: center;
            justify-content: space-around;
        }

        strong {
            border-left: solid 1px #dedede;
            padding-left: 10px;
            width: 10px;
            text-align: center;
        }
    }
}