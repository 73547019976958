@import "../../variables";

.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $colour-not-quite-so-dark;

    p {
        margin: 0;
        color: #fff;
    }

    svg {
        height: 32px;
    }
}