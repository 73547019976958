@import "../../variables.scss";

.match-renderer {
  max-width: 540px;
  background-color: #000000aa;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @import "./effects/_general.scss";
  @import "./effects/mario.scss";
  @import "./effects/dk.scss";
  @import "./effects/link.scss";
  @import "./effects/samus.scss";
  @import "./effects/kirby.scss";
  @import "./effects/yoshi.scss";
  @import "./effects/fox_falco.scss";
  @import "./effects/pikachu_pichu.scss";
  @import "./effects/captain_falcon.scss";
  @import "./effects/chrom_marcina.scss";
  @import "./effects/diddy_kong.scss";
  @import "./effects/ike.scss";
  @import "./effects/lucario.scss";
  @import "./effects/pokemon_trainer.scss";

  > div {
    background-color: $colour-not-quite-so-dark;
    box-shadow: 0px 0px 15px $colour-highlight;
    overflow-y: auto;
    max-height: 90vh;
    top: 5vh;

    .button {
      display: block;
      // width: 45px;
    }
  }

  h2 {
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    margin: 0;
    padding: 10px;
  }

  h1 {
    color: $colour-highlight;
    font-size: 96px;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    transform: translateY(-50%) translateX(-10px);
    margin: 0;
    font-style: italic;
    z-index: 2000;
  }

  .stage {
    margin: 10px;
    height: 200px;
    border: dotted 1px #ffffff66;
    position: relative;
    overflow: hidden;
    background-image: url("/assets/fd.jpg");
    background-size: auto 220px;
    background-position-y: 40px;
    background-repeat: no-repeat;
    background-color: #000000;
    background-position-x: center;

    .platform {
      position: absolute;
      width: 50%;
      left: 25%;
      top: 65%;
      border-top: dotted 1px #ffffff66;
    }
  }

  .hud {
    display: flex;
    justify-content: space-around;
    color: #ffffff;

    > div {
      width: 50%;
    }

    .strategy {
      font-size: 10px;
      letter-spacing: 2px;
      text-transform: uppercase;
      text-align: center;
      display: block;
      color: #ffffffaa;
    }

    p {
      text-align: center;
      margin: 0;
      font-size: 14px;
      font-weight: 100;
    }

    .damage {
      text-align: center;
    }

    .stocks {
      display: flex;
      justify-content: center;

      img {
        height: 20px;
      }
    }
  }

  .commentary {
    margin-top: 20px;
    padding: 10px;
    text-align: center;
    max-height: 48px;
    overflow: hidden;
    position: relative;
    background-color: $colour-secondary;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin: 0;
      color: #ffffff;
      font-size: 18px;
      text-shadow: 1px 1px 5px #000000aa;
      font-weight: 300;
    }
  }

  .button {
    margin: 20px auto;
  }

  .loader {
    height: 40px;
    min-height: 0;
    margin: 20px 0;
    width: auto;
    position: relative;
  }

  .actions {
    background-color: $colour-even-less-dark;
    border-top: solid 1px #ffffff66;

    p {
      margin: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ffffff;

      select {
        margin: 5px 0 5px 10px;
        width: 75%;
      }
    }

    &.ability p {
      margin: 5px;
      font-size: 14px;
      width: 100%;
      justify-content: center;
      font-weight: lighter;
    }

    h3 {
      background-color: $colour-dark;
      font-size: 12px;
      line-height: 18px;
      margin: 0;
      text-transform: uppercase;
      border-bottom: solid 1px #ffffff66;
      letter-spacing: 1px;
      color: #ffffff;
      text-align: center;
      font-weight: 300;
    }

    fieldset {
      border: none;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      border-bottom: solid 1px #ffffff33;

      .button {
        margin: 0 20px;
      }
    }
  }

  .intro-screen {
    .players {
      display: flex;

      .player {
        flex: 50%;
        position: relative;
        overflow-x: hidden;

        &:first-of-type {
          background-color: $colour-secondary;
        }
        &:last-of-type {
          background-color: $colour-highlight;
        }

        .fighter-portrait {
          padding: 20px;
          animation: reveal 1.5s;

          img {
            position: relative;
            left: 50%;
            height: 250px;
            transform: translateX(-50%);
          }
        }

        .player-details {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #ffffff33;

          img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin: 0 10px;
          }
        }
      }
    }
  }
}

@keyframes reveal {
  0% {
    transform: translateX(0px) translateY(0px);
    filter: brightness(0);
  }

  5% {
    transform: translateX(2px) translateY(-2px);
  }

  10% {
    transform: translateX(3px) translateY(2px);
  }

  15% {
    transform: translateX(-3px) translateY(-1px);
  }

  20% {
    transform: translateX(1px) translateY(3px);
  }

  25% {
    transform: translateX(0px) translateY(0px);
  }

  30% {
    transform: translateX(2px) translateY(-2px);
  }

  35% {
    transform: translateX(3px) translateY(2px);
  }

  40% {
    transform: translateX(-3px) translateY(-1px);
  }

  45% {
    transform: translateX(1px) translateY(3px);
  }

  50% {
    transform: translateX(0px) translateY(0px);
    filter: brightness(0);
  }

  75% {
    filter: brightness(5);
  }

  100% {
    filter: brightness(1);
  }
}
