@import "../../variables";

section.start {
  min-height: 100vh;
  padding: 40px;
  box-sizing: border-box;
  background-color: $colour-dark;

  .logo-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 40px 0;
    transition: all 0.2s ease-out;

    &.small {
      margin: -30px 0 0px 0;
      transform: scale(0.5);
    }

    .logo {
      height: 56px;
      display: block;
    }

    span {
      font-weight: 700;
      background: linear-gradient($colour-secondary, $colour-highlight);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 48px;
      margin: 0 5px;
      line-height: 56px;
    }

    p {
      width: 100%;
      color: #ffffff;
      font-weight: 200;
      text-transform: uppercase;
      margin: 0;
      text-align: center;
      letter-spacing: 4px;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 540px;
    margin: 0 auto;

    fieldset {
      font-weight: 200;
      color: #ffffff;
      border: none;
      border-bottom: solid 1px $colour-highlight;
      padding: 10px 0;

      &:last-of-type {
        border-bottom: none;
      }

      label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        small {
          display: block;
          padding-left: 20px;
        }
      }

      > dl {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        dt,
        dd {
          box-sizing: border-box;
          padding: 5px 0;
          margin: 0;
          border-bottom: solid 1px #ffffff33;
          font-weight: 300;
          display: flex;
          align-items: center;

          &:last-of-type {
            border: none;
          }
        }

        dt {
          flex: 25%;
        }

        dd {
          flex: 75%;
          justify-content: flex-end;
        }
      }
    }

    .button {
      margin: 5px 0;
    }

    .resume {
      margin: 5px 0 15px 0;
      p {
        text-align: center;
        font-size: 14px;
        margin: 0;
        font-weight: 300;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          display: block;
          height: 24px;
          margin: 0 5px;
        }
      }
    }
  }

  footer {
    text-align: center;
    width: 100%;
    color: #ffffff;
    left: 0;
    box-sizing: border-box;
    font-weight: 300;
    font-size: 13px;
    opacity: 0.4;
    padding-bottom: 40px;
  }
}
