@import "../../variables.scss";

.character-select-screen {
    margin: 15px 0;

    .selected-fighter {
        width: 250px;
        margin: 0 auto;
        padding: 10px 10px 0 10px;
        border: solid 1px $colour-secondary;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom: none;
        position: relative;
        height: 240px;
        overflow: hidden;
        display: flex;

        p {
            color: #ffffff;
            margin: 0 auto;
            font-weight: 300;
            display: flex;
            align-items: center;
            margin-top: -30px;
        }

        h2 {
            position: absolute;
            padding: 5px;
            background-color: $colour-secondary;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            box-sizing: border-box;
            top: 0;
            left: -1px;
            margin: 0;
            font-size: 16px;
            opacity: 0.8;
            color: #000000;
        }

        h3 {
            transform-origin: 0 100%;
            transform: rotateZ(-90deg) translateY(100%);
            position: absolute;
            padding: 5px;
            background-color: $colour-highlight;
            border-bottom-right-radius: 5px;
            box-sizing: border-box;
            bottom: 0;
            left: -1px;
            margin: 0;
            font-size: 11px;
            opacity: 0.8;
            color: #000000;
            line-height: 20px;
            display: flex;

            img {
                height: 20px;
                margin: 0 3px;
                transform: rotate(90deg);
            }
        }

        .render {
            width: 60%;
            display: flex;
            align-items: center;
            margin-right: -10%;

            img {
                width: 100%;
                animation: enterFighter 0.25s;
            }
        }

        .stats {
            margin-top: 0px;
            width: 50%;
            display: flex;
            flex-wrap: wrap;
            padding-left: 10px;
            color: #ffffff;
            background-color: #00000066;

            dt, dd {
                box-sizing: border-box;
                padding: 2px 0;
                margin: 0;
                border-bottom: solid 1px #ffffff33;
                font-weight: 300;
                font-size: 12px;

                &:last-of-type {
                    border: none;
                }
            }

            dt {
                width: 65%;

                &:last-of-type {
                    width: 0%;
                }
            }

            dd {
                width: 35%;
                text-align: right;

                &:last-of-type {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    .fighter-ability {
        width: 240px;
        margin: 0 auto;
        padding: 15px;
        border-top: solid 1px $colour-secondary;
        border-left: solid 1px $colour-secondary;
        border-right: solid 1px $colour-secondary;
        background-color: $colour-even-less-dark;
        height: 75px;

        h3, p {
            margin: 0;
            padding: 0;
            font-size: 16px;
            color: #ffffff;
        }

        p {
            font-weight: 200;
        }
    }

    .fighters {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        border: solid 1px #ffffff66;
        width: 304px;
        margin: 0 auto;

        .fighter {
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: solid 1px #ffffff66;
            position: relative;
            transition: background-color 0.25s linear;
            
            img {
                width: 32px;
            }

            &:after {
                content: ' ';
                display: block;
                height: 13px;
                width: 13px;
                border-radius: 50%;
                position: absolute;
                bottom: 4px;
                right: 4px;
                box-shadow: 0px 0px 7px #66000099 inset;
                opacity: 0;
                transition: opacity 0.2s linear;
            }

            &:before {
                content: ' ';
                display: block;
                height: 16px;
                width: 16px;
                background-color: $colour-primary;
                border-radius: 50%;
                position: absolute;
                bottom: 2px;
                right: 2px;
                border: solid 1px #ffffff99;
                box-shadow: 0px 0px 10px #000000;
                opacity: 0;
                transition: opacity 0.2s linear;
            }

            &.overflow {
                background-color: #ffffff66;
                border-color: transparent;
            }

            &.selected {
                background-color: #ffffff66;

                &:before, &:after {
                    opacity: 1;
                }
            }
        }
    }
}

@keyframes enterFighter {
    0% {
        opacity: 0;
        transform: translateX(-30px);
    }
    
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}