@import "../../variables.scss";

.credits {
    padding: 0 20px;
    color: #ffffff;
    text-align: center;

    .button {
        font-size: 14px;
        margin-bottom: 40px;
    }

    h2 {
        color: $colour-highlight;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: solid 1px #ffffff66;
        font-size: 18px;
    }

    ul {
        list-style: none;
        padding: 0;
        font-size: 15px;
        font-weight: 300;
    }
}