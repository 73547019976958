@import "../../variables";

.fighter-container {
    background: $colour-dark;
    color: #ffffff;

    .page-header {
        .render {
            display: block;
            height: 150%;
            position: absolute;
            top: 75%;
            transform: translateY(-50%);
            right: 5px;
        }
    }
    
    h3 {
        margin: 0 30px;
        padding: 10px 0;
        border-bottom: solid 1px #ffffffaa;
    }

    .score-history {
        width: 100%;
        
        .recharts-wrapper {
            margin: 0 auto;
        }
    }

    .fighter-ability {
        margin: 20px 30px;
        border: solid 1px $colour-highlight;
        background-color: $colour-even-less-dark;
        border-radius: 5px;
        box-shadow: 0px 0px 5px #ffffffaa;
        padding: 15px;

        h3 {
            margin: 0;
            padding: 0;
            border: none;
            font-size: 16px;

            span {
                font-weight: 400;
                color: $colour-highlight;
            }
        }

        p {
            margin-bottom: 0;
            font-weight: 300;
        }
    }

    .stats {
        dl {
            display: flex;
            flex-wrap: wrap;
            padding: 0 20px;

            &.seed {
                dt {
                    font-size: 24px;
                    line-height: 24px;
                    font-weight: 700;
                }

                dd {
                    line-height: 24px;
                    font-size: 14px;
                    font-weight: 300;
                }
            }

            dt.weight {
                display: none;
            } 

            dd.weight {
                flex: 100%;
                justify-content: center;
            }

            &.mains {
                dt {
                    display: flex;
                    align-items: center;

                    a {
                        color: #ffffff;
                        text-decoration: none;

                        &.following {
                            color: $colour-highlight;
                            font-weight: 400;
                        }
                    }

                    img {
                        height: 16px;
                        display: block;
                        margin-left: 5px;
                    }
                }

                dd {
                    .seed {
                        font-size: 14px;
                        line-height: 24px;
                        display: block;
                        margin-left: 10px;
                        width: 40px;
                        text-align: right;
                    }
                }
            }

            dt, dd {
                box-sizing: border-box;
                flex: 50%;
                padding: 5px 10px;
                margin: 0;
                border-bottom: solid 1px #ffffff33;
                font-weight: 300;

                &:last-of-type {
                    border: none;
                }
            }

            dd {
                justify-content: flex-end;
                align-items: center;
                display: flex;

                .char-icon {
                    height: 24px;
                    margin-left: 10px;
                }
            }
        }
    }

    .recharts-default-tooltip {
        background-color: $colour-not-quite-so-dark !important;
        border: solid 1px $colour-highlight !important;
        border-radius: 5px;
        display: flex;

        .recharts-tooltip-label {
            color: #ffffff;
            padding-right: 5px;
        }

        .recharts-tooltip-item-list {
            .recharts-tooltip-item {
                padding: 0 !important;

                .recharts-tooltip-item-separator { 
                    display: none;
                }
            }
        }
    }
}