.fighter.fighter-lucario {
    &.fighter-recovering {
        transition: none;

        &.fighter-one {
            animation: auraRecovery 0.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            left: 25%;
        }

        &.fighter-two {
            animation: auraRecoveryReverse 0.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            right: 25%;
        }
    }
}

.fighter.opponent-lucario {
    
}

.fighter-lucario-effects {
    &.fighter-one-effects {
        &.fighter-advantage-effects {
            div:first-of-type {
                height: 32px;
                width: 32px;
                position: absolute;
                top: calc(65% - 24px);
                background-image: url('/assets/effects/lucario/aura.png');
                background-size: 32px;
                background-repeat: no-repeat;
                left: calc(75% - 24px);
                animation: auraCombo 0.3s infinite;
                animation-delay: 0.3s;
                opacity: 0;
            }
        }
        
        &.fighter-recovering-effects {
            div:first-of-type {
                animation: auraRecoveryEffect 0.4s;
                animation-timing-function: linear;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                height: 32px;
                width: 32px;
                position: absolute;
                top: calc(65% - 32px);
                left: 25%;
                background-image: url('/assets/effects/lucario/aura_recover.png');
                background-size: 32px;
                background-repeat: no-repeat;
                filter: brightness(2);
            }
        }
    }
    
    &.fighter-two-effects {
        &.fighter-advantage-effects {
            div:first-of-type {
                height: 32px;
                width: 32px;
                position: absolute;
                top: calc(65% - 24px);
                background-image: url('/assets/effects/lucario/aura.png');
                background-size: 32px;
                background-repeat: no-repeat;
                right: calc(75% - 24px);
                animation: auraCombo 0.3s infinite;
                animation-delay: 0.3s;
		        animation-direction: reverse;
                opacity: 0;
            }
        }
        
        &.fighter-recovering-effects {
            div:first-of-type {
                animation: auraRecoveryEffectReverse 0.4s;
                animation-timing-function: linear;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                height: 32px;
                width: 32px;
                position: absolute;
                top: calc(65% - 32px);
                right: 25%;
                background-image: url('/assets/effects/lucario/aura_recover.png');
                background-size: 32px;
                background-repeat: no-repeat;
                filter: brightness(2);
            }
        }
    }
}

@keyframes auraRecovery {
    0% { 
        transform: translateX(-100px) translateY(100px) rotate(45deg);
    }
    
    100% {
        transform: translateX(0px) translateY(0px) rotate(0deg);
    }
}

@keyframes auraRecoveryReverse {
    0% { 
        transform: translateX(100px) translateY(100px) rotate(-45deg);
    }
    
    100% {
        transform: translateX(0px) translateY(0px) rotate(0deg);
    }
}

@keyframes auraRecoveryEffect {
    0% { 
        transform: translateX(-100px) translateY(100px) rotate(45deg);
        opacity: 1;
    }
    
    100% {
        transform: translateX(0px) translateY(0px) rotate(0deg);
        opacity: 0;
    }
}

@keyframes auraRecoveryEffectReverse {
    0% { 
        transform: translateX(100px) translateY(100px) rotate(-45deg);
        opacity: 1;
    }
    
    100% {
        transform: translateX(0px) translateY(0px) rotate(0deg);
        opacity: 0;
    }
}

@keyframes auraCombo {
    0% {
        opacity: 0;
    }

    75% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
        transform: scale(1.5) rotate(45deg);
    }
}
