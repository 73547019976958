@import "../../variables.scss";

.news {
  max-width: 1024px;
  margin: 0 auto;
  padding: 20px;
  color: #ffffff;
  min-height: 100vh;

  > div {
    border: solid 1px $colour-secondary;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 1px $colour-highlight;
    margin-bottom: 7.5px;
    background-color: $colour-even-less-dark;
    position: relative;
    z-index: 2;
    overflow: hidden;

    a {
      color: inherit;
      text-decoration: none;
    }

    h2 {
      margin: 0;
      padding: 10px 15px 10px 80px;
      font-size: 14px;
      font-weight: normal;
      position: relative;

      &:after {
        content: " ";
        display: block;
        position: absolute;
        right: 15px;
        top: 17px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 8px 0 8px;
        border-color: $colour-highlight transparent transparent transparent;
        transition: all 0.15s linear;
      }

      &.open {
        &:after {
          border-width: 0 8px 8px 8px;
          border-color: transparent transparent $colour-highlight transparent;
        }
      }
    }

    time {
      font-size: 12px;
      line-height: 21px;
      background-color: $colour-secondary;
      display: block;
      color: #000000;
      position: absolute;
      left: 0;
      padding: 10px;
    }

    .content {
      max-height: 0px;
      overflow: hidden;
      transition: max-height 0.5s ease-out;

      &.open {
        max-height: 50vh;
        overflow: auto;
      }
    }
  }

  .char-icon {
    height: 32px;
  }

  h1 {
    color: #ffffff;
  }

  .news- {
    &results {
      .result-container {
        display: flex;
        flex-wrap: wrap;
      }

      ol {
        flex: 1;
        display: flex;
        margin: 0;
        padding: 0;
        flex-wrap: wrap;

        a {
          width: 50%;
        }

        &:first-of-type {
          a {
            width: 100%;
          }

          li:first-of-type {
            height: 100%;
            border-right: solid 1px $colour-highlight;

            &:before {
              content: "1";
            }

            &:after {
              content: "1";
            }

            .char-icon {
              height: 130%;
            }
          }
        }

        li {
          border-top: solid 1px $colour-highlight;
          width: 50%;
          box-sizing: border-box;
          position: relative;
          list-style: none;
          height: 40px;
          text-align: right;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          padding: 10px;
          overflow: hidden;

          &[value="3"] {
            font-size: 12px;
          }

          span {
            display: block;
            z-index: 1;
            position: absolute;
            background-color: #000000aa;
            bottom: 0;
            right: 0;
            width: 100%;
            padding: 5px;

            > img {
              height: 44px;
              position: absolute;
              left: 15px;
              bottom: 5px;
              border-radius: 50%;
            }
          }

          &:after {
            font-style: italic;
            font-size: 48px;
            line-height: 48px;
            color: $colour-primary;
            position: absolute;
            top: 5px;
            right: 15px;
            font-weight: bold;
            text-shadow: 0px 0px 10px $colour-secondary;
            z-index: 2;
          }

          &:before {
            font-style: italic;
            font-size: 48px;
            line-height: 48px;
            color: #000;
            position: absolute;
            top: 7px;
            right: 13px;
            font-weight: bold;
          }

          .char-icon {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 200%;
            opacity: 0.9;
          }

          &:first-of-type {
            width: 100%;
            height: 100px;

            &:before,
            &:after {
              content: "2";
            }
          }

          &:nth-of-type(2),
          &:nth-of-type(3) {
            height: 75px;
          }

          &:nth-of-type(2),
          &:nth-of-type(4),
          &:nth-of-type(6) {
            border-right: solid 1px $colour-highlight;
          }

          &:nth-of-type(2),
          &:nth-of-type(3),
          &:nth-of-type(4),
          &:nth-of-type(5),
          &:nth-of-type(6),
          &:nth-of-type(7) {
            &:before,
            &:after {
              font-size: 24px;
              line-height: 24px;
            }
            &:before {
              bottom: 4px;
              left: 9px;
            }
            &:after {
              bottom: 5px;
              left: 10px;
            }
          }
        }

        &:nth-of-type(2) {
          a {
            &:first-of-type {
              width: 100%;
            }

            &:nth-of-type(2) li {
              border-right: solid 1px $colour-highlight;
            }

            &:nth-of-type(2) li:before,
            &:nth-of-type(2) li:after {
              content: "3";
            }
            &:nth-of-type(3) li:before,
            &:nth-of-type(3) li:after {
              content: "3";
            }
          }
        }

        &:last-of-type {
          flex: 100%;

          li {
            height: 40px;
            font-size: 12px;
            width: 100%;
            border-right: solid 1px $colour-highlight;

            .char-icon {
              left: 0px;
              transform: none;
            }

            span {
              padding-right: 25px;
              display: block;
              background: none;
              line-height: 28px;
            }

            &:before,
            &:after {
              right: 10px;
              content: "5";
              font-size: 20px;
              line-height: 28px;
            }
          }
        }
      }
    }

    &sponsorship {
      h2 {
        font-weight: normal;

        &:after {
          display: none;
        }
      }

      .content {
        p {
          font-size: 14px;
          margin: 0px 15px 10px 15px;
        }
      }
    }

    &balance-patch {
      .balance-patch {
        padding: 0;
        margin: 0 0 15px 0;

        > li {
          background-color: $colour-secondary;
          margin: 0 10px;
          padding: 0 5px;
          list-style-type: none;
          text-align: center;
          font-weight: 500;
          font-size: 16px;

          &:nth-of-type(3),
          &:nth-of-type(4) {
            background-color: $colour-primary;
          }
        }

        ul {
          margin: 0;
          padding: 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;

          li {
            list-style-type: none;
            padding: 0;
            margin: 3px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    &character-changes {
      > div {
        padding: 0 20px;

        p {
          margin: 0;
          padding: 4px 0;
          display: flex;
          justify-content: flex-end;
          border-bottom: solid 1px #ffffff66;
          line-height: 32px;
          position: relative;

          &:last-of-type {
            border-bottom: none;
            padding-bottom: 20px;
          }

          img {
            height: 32px;

            &:first-of-type {
              padding-right: 15px;
            }
          }

          &:after {
            content: " ";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 4px 0 4px 8px;
            border-color: transparent transparent transparent $colour-secondary;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 34px;
          }

          span {
            flex: 100%;
          }
        }
      }
    }

    &stream {
      .content {
        .video-thumbnail {
          padding: 10px 30px;
        }

        > p {
          margin: 0;
          padding: 20px 30px;
          font-size: 14px;
        }
      }
    }

    &tweet,
    &reaction_tweet {
      position: relative;

      .content {
        max-height: none;

        img {
          position: absolute;
          top: 10px;
          left: 10px;
          width: 42px;
          border-radius: 50%;
        }

        p {
          margin-top: 0;
          padding: 0 10px 0 60px;
          font-size: 12px;
          line-height: 18px;
        }

        a {
          font-weight: 700;
          color: $colour-highlight;
        }
      }

      h2 {
        padding-left: 60px;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 14px;
        padding-right: 68px;
        flex-wrap: wrap;

        .sponsor-emblem {
          height: 32px;
          margin-right: 5px;
        }

        span {
          opacity: 0.6;
        }

        &:after {
          display: none;
        }
      }
    }

    &tweet {
      time {
        position: relative;
      }

      .content {
        img {
          top: 50px;
        }
      }
    }

    &reaction_tweet {
      margin-top: -10px;
      box-shadow: none;
      width: calc(100% - 15px);
      margin-left: 5px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      z-index: 1;

      time {
        display: none;
        border-top-right-radius: 0;
      }
    }

    &dlc {
      h2 {
        &:after {
          display: none;
        }
      }

      .content {
        max-height: 25vh;
        overflow: hidden;

        .char-icon {
          height: auto;
          width: 80%;
          margin: 0 auto;
          display: block;
        }
      }
    }
  }
}
