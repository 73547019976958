.fighter.fighter-pikachu,
.fighter.fighter-pichu {
    &.fighter-neutral {
        &.fighter-two {
            animation-delay: 0.2s;
        }
    }

    &.fighter-recovering {
        transition: none;

        &.fighter-one {
            animation: quickAttack 0.75s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            left: 25%;
        }

        &.fighter-two {
            animation: quickAttackReverse 0.75s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            right: 25%;
        }
    }
}

.fighter.opponent-pikachu,
.fighter.opponent-pichu {
    
}

.fighter-pikachu-effects,
.fighter-pichu-effects {
    &.fighter-one-effects {
        &.fighter-neutral-effects {
            &.strategy-campy-effects {
                div:first-of-type {
                    animation: jolt 0.75s;
                    animation-timing-function: linear;
                    animation-iteration-count: infinite;
                    height: 24px;
                    width: 24px;
                    position: absolute;
                    top: calc(65% - 24px);
                    background-image: url('/assets/effects/pikachu/jolt.png');
                    background-size: 24px;
                    background-repeat: no-repeat;
                }
                
                div:nth-of-type(2) {
                    animation: joltTrail 0.75s;
                    animation-timing-function: linear;
                    animation-iteration-count: infinite;
                    height: 24px;
                    width: 24px;
                    position: absolute;
                    top: calc(65% - 24px);
                    background-image: url('/assets/effects/pikachu/bolt.png');
                    background-size: 24px;
                    background-repeat: no-repeat;
                }
            }
        }

        &.fighter-advantage-effects {
            div:first-of-type {
                height: 64px;
                width: 64px;
                position: absolute;
                top: calc(65% - 48px);
                background-image: url('/assets/effects/pikachu/jolt.png');
                background-size: 64px;
                background-repeat: no-repeat;
                left: calc(75% - 48px);
                animation: nairLoop 1s infinite;
                opacity: 0;
            }
            
            div:nth-of-type(2) {
                height: 64px;
                width: 64px;
                position: absolute;
                top: calc(65% - 52px);
                background-image: url('/assets/effects/pikachu/jolt.png');
                background-size: 64px;
                background-repeat: no-repeat;
                left: calc(75% - 52px);
                animation: nairLoop 1s infinite;
                animation-delay: 0.025s;
                opacity: 0;
            }

            div:nth-of-type(3) {
                height: 64px;
                width: 64px;
                position: absolute;
                top: calc(65% - 44px);
                background-image: url('/assets/effects/pikachu/bolt.png');
                background-size: 64px;
                background-repeat: no-repeat;
                left: calc(75% - 44px);
                animation: nairLoop 1s infinite;
                animation-delay: 0.05s;
                opacity: 0;
            }

            div:nth-of-type(4) {
                height: 64px;
                width: 64px;
                position: absolute;
                top: calc(65% - 48px);
                background-image: url('/assets/effects/pikachu/jolt.png');
                background-size: 64px;
                background-repeat: no-repeat;
                left: calc(75% - 44px);
                animation: nairLoop 1s infinite;
                animation-delay: 0.075s;
                opacity: 0;
            }

            div:nth-of-type(5) {
                height: 64px;
                width: 64px;
                position: absolute;
                top: calc(65% - 44px);
                background-image: url('/assets/effects/pikachu/jolt.png');
                background-size: 64px;
                background-repeat: no-repeat;
                left: calc(75% - 48px);
                animation: nairLoop 1s infinite;
                animation-delay: 0.1s;
                opacity: 0;
            }
        }
        
        &.fighter-recovering-effects {
            div:first-of-type {
                animation: quickAttackEffects 0.75s;
                animation-timing-function: linear;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                height: 24px;
                width: 24px;
                position: absolute;
                top: calc(65% - 32px);
                left: calc(25% - 12px);
                background-image: url('/assets/effects/pikachu/bolt.png');
                background-size: 24px;
                background-repeat: no-repeat;
                opacity: 0;
                filter: brightness(3);
            }
            
            div:nth-of-type(2) {
                animation: quickAttackEffects 0.75s;
                animation-timing-function: linear;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                height: 24px;
                width: 24px;
                position: absolute;
                top: calc(65% - 24px);
                left: calc(25% + 12px);
                background-image: url('/assets/effects/pikachu/bolt.png');
                background-size: 24px;
                background-repeat: no-repeat;
                opacity: 0;
                filter: brightness(3);
            }
        }
    }
    
    &.fighter-two-effects {
        &.fighter-neutral-effects {
            &.strategy-campy-effects {
                div:first-of-type {
                    animation: joltReverse 0.75s;
                    animation-delay: 0.2s;
                    animation-timing-function: linear;
                    animation-iteration-count: infinite;
                    height: 24px;
                    width: 24px;
                    position: absolute;
                    top: calc(65% - 24px);
                    background-image: url('/assets/effects/pikachu/jolt.png');
                    background-size: 24px;
                    background-repeat: no-repeat;
                    opacity: 0;
                }
                
                div:nth-of-type(2) {
                    animation: joltTrailReverse 0.75s;
                    animation-delay: 0.2s;
                    animation-timing-function: linear;
                    animation-iteration-count: infinite;
                    height: 24px;
                    width: 24px;
                    position: absolute;
                    top: calc(65% - 24px);
                    background-image: url('/assets/effects/pikachu/bolt.png');
                    background-size: 24px;
                    background-repeat: no-repeat;
                    opacity: 0;
                }
            }
        }

        &.fighter-advantage-effects {
            div {
                transform-origin: -100% 0;
            }

            div:first-of-type {
                height: 64px;
                width: 64px;
                position: absolute;
                top: calc(65% - 48px);
                background-image: url('/assets/effects/pikachu/jolt.png');
                background-size: 64px;
                background-repeat: no-repeat;
                right: calc(75% - 48px);
                animation: nairLoop 1s infinite;
                opacity: 0;
            }
            
            div:nth-of-type(2) {
                height: 64px;
                width: 64px;
                position: absolute;
                top: calc(65% - 52px);
                background-image: url('/assets/effects/pikachu/jolt.png');
                background-size: 64px;
                background-repeat: no-repeat;
                right: calc(75% - 52px);
                animation: nairLoop 1s infinite;
                animation-delay: 0.025s;
                opacity: 0;
            }

            div:nth-of-type(3) {
                height: 64px;
                width: 64px;
                position: absolute;
                top: calc(65% - 44px);
                background-image: url('/assets/effects/pikachu/bolt.png');
                background-size: 64px;
                background-repeat: no-repeat;
                right: calc(75% - 44px);
                animation: nairLoop 1s infinite;
                animation-delay: 0.05s;
                opacity: 0;
            }

            div:nth-of-type(4) {
                height: 64px;
                width: 64px;
                position: absolute;
                top: calc(65% - 48px);
                background-image: url('/assets/effects/pikachu/jolt.png');
                background-size: 64px;
                background-repeat: no-repeat;
                right: calc(75% - 44px);
                animation: nairLoop 1s infinite;
                animation-delay: 0.075s;
                opacity: 0;
            }

            div:nth-of-type(5) {
                height: 64px;
                width: 64px;
                position: absolute;
                top: calc(65% - 44px);
                background-image: url('/assets/effects/pikachu/jolt.png');
                background-size: 64px;
                background-repeat: no-repeat;
                right: calc(75% - 48px);
                animation: nairLoop 1s infinite;
                animation-delay: 0.1s;
                opacity: 0;
            }
        }
        
        &.fighter-recovering-effects {
            div:first-of-type {
                animation: quickAttackEffectsReverse 0.75s;
                animation-timing-function: linear;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                height: 24px;
                width: 24px;
                position: absolute;
                top: calc(65% - 32px);
                right: calc(25% - 12px);
                background-image: url('/assets/effects/pikachu/bolt.png');
                background-size: 24px;
                background-repeat: no-repeat;
                opacity: 0;
                filter: brightness(3);
            }
            
            div:nth-of-type(2) {
                animation: quickAttackEffectsReverse 0.75s;
                animation-timing-function: linear;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                height: 24px;
                width: 24px;
                position: absolute;
                top: calc(65% - 24px);
                right: calc(25% + 12px);
                background-image: url('/assets/effects/pikachu/bolt.png');
                background-size: 24px;
                background-repeat: no-repeat;
                opacity: 0;
                filter: brightness(3);
            }
        }
    }
}

@keyframes jolt {
    0% {
        left: 25%;
        opacity: 0;
        transform: translateY(0px);
    }

    32% { 
        left: 25%;
        opacity: 0;
        transform: translateY(0px);
    }

    33% { 
        transform: translateY(-30px);
    }

    40% {
        opacity: 1;
    }

    50% { 
        transform: translateY(-20px);
    }

    66% { 
        transform: translateY(0px);
    }

    83% { 
        transform: translateY(-20px);
        opacity: 1;
    }

    100% {
        transform: translateY(0px);
        left: 75%;
        opacity: 0;
    }
}

@keyframes joltTrail {
    0% {
        left: 25%;
        opacity: 0;
        transform: translateY(0px) translateX(-12px) rotateZ(-30deg);
    }

    32% { 
        left: 25%;
        opacity: 0;
        transform: translateY(0px) translateX(-12px) rotateZ(30deg);
    }

    33% {
        transform: translateY(-30px) translateX(-12px) rotateZ(30deg);
    }

    40% {
        opacity: 1;
    }

    50% { 
        transform: translateY(-20px) translateX(-12px) rotateZ(30deg);
    }

    66% { 
        transform: translateY(0px) translateX(-12px) rotateZ(60deg);
    }

    67% { 
        transform: translateY(0px) translateX(-12px) rotateZ(-60deg);
    }

    83% { 
        transform: translateY(-20px) translateX(-12px) rotateZ(-30deg);
        opacity: 1;
    }

    100% {
        transform: translateY(0px) translateX(-12px) rotateZ(30deg);
        left: 75%;
        opacity: 0;
    }
}

@keyframes joltReverse {
    0% {
        right: 25%;
        opacity: 0;
        transform: translateY(0px);
    }

    32% { 
        right: 25%;
        opacity: 0;
        transform: translateY(0px);
    }

    33% { 
        opacity: 1;
        transform: translateY(-30px);
    }

    50% { 
        transform: translateY(-20px);
    }

    66% { 
        transform: translateY(0px);
    }

    83% { 
        transform: translateY(-20px);
        opacity: 1;
    }

    100% {
        transform: translateY(0px);
        right: 75%;
        opacity: 0;
    }
}

@keyframes joltTrailReverse {
    0% {
        right: 25%;
        opacity: 0;
        transform: translateY(0px) translateX(12px) rotateZ(-30deg);
    }

    32% { 
        right: 25%;
        opacity: 0;
        transform: translateY(0px) translateX(12px) rotateZ(30deg);
    }

    33% { 
        opacity: 1;
        transform: translateY(-30px) translateX(12px) rotateZ(30deg);
    }

    50% { 
        transform: translateY(-20px) translateX(12px) rotateZ(30deg);
    }

    66% { 
        transform: translateY(0px) translateX(12px) rotateZ(60deg);
    }

    67% { 
        transform: translateY(0px) translateX(12px) rotateZ(-60deg);
    }

    83% { 
        transform: translateY(-20px) translateX(12px) rotateZ(-30deg);
        opacity: 1;
    }

    100% {
        transform: translateY(0px) translateX(12px) rotateZ(30deg);
        right: 75%;
        opacity: 0;
    }
}

@keyframes quickAttack {
    0% { 
        transform: translateX(-100px) translateY(100px) scaleX(1) scaleY(1);
    }

    12.5% {
        transform: translateX(-100px) translateY(50px) scaleX(0.5) scaleY(1);
    }

    25% {
        transform: translateX(-100px) translateY(0px) scaleX(1) scaleY(1);
    }
    
    50% { 
        transform: translateX(-100px) translateY(0px) scaleX(1) scaleY(1);
    }

    62.5% {
        transform: translateX(-50px) translateY(0px) scaleX(1) scaleY(0.5);
    }

    75% {
        transform: translateX(0px) translateY(0px) scaleX(1) scaleY(1);
    }
    
    100% {
        transform: translateX(0px) translateY(0px) scaleX(1) scaleY(1);
    }
}

@keyframes quickAttackEffects {
    0% { 
        transform: translateX(-100px) translateY(100px) rotateZ(90deg);
    }

    12.5% {
        transform: translateX(-100px) translateY(50px) rotateZ(90deg);
    }

    25% {
        transform: translateX(-100px) translateY(0px) rotateZ(90deg);
    }
    
    49% {
        transform: translateX(-100px) translateY(0px) rotateZ(90deg);
    }

    50% { 
        transform: translateX(-100px) translateY(0px) rotateZ(0deg);
    }

    62.5% {
        transform: translateX(-50px) translateY(0px) rotateZ(0deg);
    }

   
    75% {
        transform: translateX(0px) translateY(0px) rotateZ(0deg);
        opacity: 1;
    }

    76% {
        opacity: 0;
    }
    
    100% {
        transform: translateX(0px) translateY(0px) rotateZ(0deg);
        opacity: 0;
    }
}

@keyframes quickAttackReverse {
    0% { 
        transform: translateX(100px) translateY(100px) scaleX(1) scaleY(1);
    }

    12.5% {
        transform: translateX(100px) translateY(50px) scaleX(0.5) scaleY(1);
    }

    25% {
        transform: translateX(100px) translateY(0px) scaleX(1) scaleY(1);
    }
    
    50% { 
        transform: translateX(100px) translateY(0px) scaleX(1) scaleY(1);
    }

    62.5% {
        transform: translateX(50px) translateY(0px) scaleX(1) scaleY(0.5);
    }

    75% {
        transform: translateX(0px) translateY(0px) scaleX(1) scaleY(1);
    }
    
    100% {
        transform: translateX(0px) translateY(0px) scaleX(1) scaleY(1);
    }
}

@keyframes quickAttackEffectsReverse {
    0% { 
        transform: translateX(100px) translateY(100px) rotateZ(90deg);
    }

    12.5% {
        transform: translateX(100px) translateY(50px) rotateZ(90deg);
    }

    25% {
        transform: translateX(100px) translateY(0px) rotateZ(90deg);
    }
    
    49% {
        transform: translateX(100px) translateY(0px) rotateZ(90deg);
    }

    50% { 
        transform: translateX(100px) translateY(0px) rotateZ(0deg);
    }

    62.5% {
        transform: translateX(50px) translateY(0px) rotateZ(0deg);
    }

    75% {
        transform: translateX(0px) translateY(0px) rotateZ(0deg);
        opacity: 1;
    }

    76% {
        opacity: 0;
    }
    
    100% {
        transform: translateX(0px) translateY(0px) rotateZ(0deg);
        opacity: 0;
    }
}

@keyframes nairLoop {
    0% {
        transform: translateY(0px) translateX(0px) scale(1);
        opacity: 0;
    }

    30% {
        opacity: 0;
        transform: translateY(0px) translateX(0px) scale(1);
    }

    35% {
        opacity: 0;
        transform: translateY(0px) translateX(-5px) scale(1.2);
    }

    40% {
        opacity: 0;
        transform: translateY(0px) translateX(0px) scale(1);
    }

    50% {
        opacity: 0;
        transform: translateY(0px) translateX(0px) scale(1);
    }

    55% {
        opacity: 0.5;
        transform: translateY(0px) translateX(-5px) scale(1.2);
    }

    60% {
        opacity: 0;
        transform: translateY(0px) translateX(0px) scale(1);
    }

    70% {
        opacity: 0;
        transform: translateY(0px) translateX(0px) scale(1);
    }

    75% {
        opacity: 0.5;
        transform: translateY(0px) translateX(-5px) scale(1.2);
    }

    80% {
        opacity: 0;
        transform: translateY(0px) translateX(0px) scale(1);
    }

    90% {
        opacity: 0;
        transform: translateY(0px) translateX(0px) scale(1);
    }

    95% {
        opacity: 0.5;
        transform: translateY(0px) translateX(-5px) scale(1.2);
    }

    100% {
        opacity: 0;
        transform: translateY(0px) translateX(0px) scale(1);
    }
}
