@import "../../variables.scss";

.stat-adder {
    display: flex;
    align-items: center;

    .button {
        display: flex;
        align-items: center;
        padding: 5px;
        height: 15px;
        margin: 5px 0 !important;
    }

    em {
        margin: 0 1px;
        height: 18px;
        width: 3px;
        border: solid 1px $colour-highlight;
        border-radius: 3px;

        &.selected {
            background-color: $colour-highlight;
        }

        &:first-of-type {
            margin-left: 5px;
        }
        
        &:last-of-type {
            margin-right: 5px;
        }
    }
}