.fighter {
    height: 32px;
    position: absolute;
    top: calc(65% - 32px);
    transition: left 0.5s linear, right 0.5s linear, top 0.5s linear, filter 0.5s linear;

    &.fighter-neutral {
        &.strategy-balanced, &.strategy-pressure,
        &.strategy-cargo_throw
        {
            animation: neutralIdle 1s infinite;
        }
        
        &.strategy-campy, &.strategy-counter {
            animation: neutralFullHop 0.75s infinite;
        }
    }

    &.fighter-respawning,
    &.fighter-respawning-from-bottom {
        top: 33%;
        transition: none;
        animation: respawn 1.2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    &.fighter-vulnerable {
        animation-delay: 0.5s;
        animation: vulnerable 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    &.fighter-recovering {
        animation: recover 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    &.fighter-self-destructing {
        animation-iteration-count: 1;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    }
    
    &.fighter-defeated {
      display: none;
    }

    &.fighter-one {
        left: 25%;

        &.fighter-advantage {
            left: calc(75% - 42px);
            animation: comboLeft 1s infinite;
            z-index: 200;
        }

        &.fighter-disadvantage {
            animation: disadvantageRight 1s infinite;
        }
        
        &.fighter-punishing {
            left: calc(75% - 42px);
            animation: punishLeft 1s;
            animation-iteration-count: 1;
            z-index: 200;
        }

        &.fighter-respawning {
            left: 50%;
            transform: translateX(-50%);
        }

        &.fighter-vulnerable {
            left: -7.5%;
        }
        
        &.fighter-recovering {
            left: calc(12.5% + 32px);
        }

        &.fighter-self-destructing {
            animation: selfDestruct 2s;
            left: calc(75% - 42px);
            animation-iteration-count: 1;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        }
        
        &.fighter-edgeguarding {
            animation: edgeguard 1.4s;
            animation-iteration-count: 1;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
            left: calc(75% - 42px);
        }

        &.fighter-edgeguarded {
            animation: edgeguarded 1.4s;
            animation-iteration-count: 1;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        }
    }

    &.fighter-two {
        right: 25%;

        &.fighter-neutral {
            animation-direction: reverse;
        }

        &.fighter-advantage {
            right: calc(75% - 42px);
            animation: comboRight 1s infinite;
            z-index: 200;
        }

        &.fighter-disadvantage {
            animation: disadvantageRight 1s infinite;
        }
        
        &.fighter-punishing {
            right: calc(75% - 42px);
            animation: punishRight 1s;
            animation-iteration-count: 1;
            z-index: 200;
        }
        
        &.fighter-respawning {
            right: 50%;
            transform: translateX(50%);
        }

        &.fighter-vulnerable {
            right: -7.5%;
        }

        &.fighter-recovering {
            right: calc(12.5% + 32px);
        }
        
        &.fighter-self-destructing {
            animation: selfDestructReverse 1.4s;
            right: calc(75% - 42px);
            animation-iteration-count: 1;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        }

        &.fighter-edgeguarding {
            animation: edgeguardReverse 1.4s;
            animation-iteration-count: 1;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
            right: calc(75% - 42px);
        }

        &.fighter-edgeguarded {
            animation: edgeguardedReverse 1.4s;
            animation-iteration-count: 1;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        }
    }

    &.bonus {
        filter: drop-shadow(0px 0px 5px #ffffff)
    }
}

.fighter-one-effects {
    &.fighter-respawning-effects,
    &.fighter-defeated-effects {
        div {
            &:nth-of-type(1), 
            &:nth-of-type(2), 
            &:nth-of-type(3) {
                height: 20px;
                width: 20px;
                background-image: url('/assets/effects/star.png');
                background-size: 100%;
                background-repeat: no-repeat;
                position: absolute;
                left: -20px;
                animation: flyingStars 1s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
            }

            &:nth-of-type(1) {
                transform-origin: 2px 2px;
            }

            &:nth-of-type(2) {
                transform-origin: 4px 4px;
                animation-delay: 0.05s;
                margin-top: -20px;
            }

            &:nth-of-type(3) {
                transform-origin: 6px 6px;
                animation-delay: 0.1s;
                margin-top: 20px;
            }

            &:nth-of-type(4) {
                height: 50px;
                width: 50px;
                opacity: 0;
                border-radius: 50px;
                background-color: #ffffffaa;
                box-shadow: 0 0 15px #ffffffaa;
                position: absolute;
                left: -25px;
                top: 50px;
                animation: growFade 1s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
            }
        }
    }
    
    &.fighter-edgeguarded-effects {
        div {
            &:nth-of-type(1), 
            &:nth-of-type(2), 
            &:nth-of-type(3) {
                height: 20px;
                width: 20px;
                background-image: url('/assets/effects/star.png');
                background-size: 100%;
                background-repeat: no-repeat;
                position: absolute;
                margin-left: 25px;
                margin-top: 75px;
                animation: flyingStars 1s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                animation-delay: 0.6s;
                opacity: 0;
            }

            &:nth-of-type(1) {
                transform-origin: 2px 2px;
            }

            &:nth-of-type(2) {
                transform-origin: 4px 4px;
                animation-delay: 0.65s;
            }

            &:nth-of-type(3) {
                transform-origin: 6px 6px;
                animation-delay: 0.7s;
            }

            &:nth-of-type(4) {
                height: 50px;
                width: 50px;
                opacity: 0;
                border-radius: 50px;
                background-color: #ffffffaa;
                box-shadow: 0 0 15px #ffffffaa;
                position: absolute;
                left: calc(25% - 75px);
                bottom: -25px;
                animation: growFade 1s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                animation-delay: 0.6s;
            }
        }
    }

    &.fighter-self-destructing-effects {
        div {
            &:nth-of-type(1), 
            &:nth-of-type(2), 
            &:nth-of-type(3) {
                height: 20px;
                width: 20px;
                background-image: url('/assets/effects/star.png');
                background-size: 100%;
                background-repeat: no-repeat;
                position: absolute;
                margin-left: -25px;
                margin-top: 75px;
                animation: flyingStarsReverse 1s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                animation-delay: 0.8s;
                opacity: 0;
            }

            &:nth-of-type(1) {
                transform-origin: 2px 2px;
            }

            &:nth-of-type(2) {
                transform-origin: 4px 4px;
                animation-delay: 0.85s;
            }

            &:nth-of-type(3) {
                transform-origin: 6px 6px;
                animation-delay: 0.9s;
            }

            &:nth-of-type(4) {
                height: 50px;
                width: 50px;
                opacity: 0;
                border-radius: 50px;
                background-color: #ffffffaa;
                box-shadow: 0 0 15px #ffffffaa;
                position: absolute;
                right: calc(25% - 100px);
                bottom: -25px;
                animation: growFade 1s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                animation-delay: 0.8s;
            }
        }
    }
}

.fighter-two-effects {
    &.fighter-respawning-effects,
    &.fighter-defeated-effects {
        div {
            &:nth-of-type(1), 
            &:nth-of-type(2), 
            &:nth-of-type(3) {
                height: 20px;
                width: 20px;
                background-image: url('/assets/effects/star.png');
                background-size: 100%;
                background-repeat: no-repeat;
                position: absolute;
                right: -20px;
                animation: flyingStarsReverse 1s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
            }

            &:nth-of-type(1) {
                transform-origin: 2px 2px;
            }

            &:nth-of-type(2) {
                transform-origin: 4px 4px;
                animation-delay: 0.05s;
                margin-top: -20px;
            }

            &:nth-of-type(3) {
                transform-origin: 6px 6px;
                animation-delay: 0.1s;
                margin-top: 20px;
            }

            &:nth-of-type(4) {
                height: 50px;
                width: 50px;
                opacity: 0;
                border-radius: 50px;
                background-color: #ffffffaa;
                box-shadow: 0 0 15px #ffffffaa;
                position: absolute;
                right: -25px;
                top: 50px;
                animation: growFade 1s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
            }
        }
    }

    &.fighter-edgeguarded-effects {
        div {
            &:nth-of-type(1), 
            &:nth-of-type(2), 
            &:nth-of-type(3) {
                height: 20px;
                width: 20px;
                background-image: url('/assets/effects/star.png');
                background-size: 100%;
                background-repeat: no-repeat;
                position: absolute;
                margin-left: -25px;
                margin-top: 75px;
                animation: flyingStarsReverse 1s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                animation-delay: 0.6s;
                opacity: 0;
            }

            &:nth-of-type(1) {
                transform-origin: 2px 2px;
            }

            &:nth-of-type(2) {
                transform-origin: 4px 4px;
                animation-delay: 0.65s;
            }

            &:nth-of-type(3) {
                transform-origin: 6px 6px;
                animation-delay: 0.7s;
            }

            &:nth-of-type(4) {
                height: 50px;
                width: 50px;
                opacity: 0;
                border-radius: 50px;
                background-color: #ffffffaa;
                box-shadow: 0 0 15px #ffffffaa;
                position: absolute;
                right: calc(25% - 75px);
                bottom: -25px;
                animation: growFade 1s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                animation-delay: 0.6s;
            }
        }
    }

    &.fighter-self-destructing-effects {
        div {
            &:nth-of-type(1), 
            &:nth-of-type(2), 
            &:nth-of-type(3) {
                height: 20px;
                width: 20px;
                background-image: url('/assets/effects/star.png');
                background-size: 100%;
                background-repeat: no-repeat;
                position: absolute;
                margin-left: 25px;
                margin-top: 75px;
                animation: flyingStars 1s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                animation-delay: 0.8s;
                opacity: 0;
            }

            &:nth-of-type(1) {
                transform-origin: 2px 2px;
            }

            &:nth-of-type(2) {
                transform-origin: 4px 4px;
                animation-delay: 0.85s;
            }

            &:nth-of-type(3) {
                transform-origin: 6px 6px;
                animation-delay: 0.9s;
            }

            &:nth-of-type(4) {
                height: 50px;
                width: 50px;
                opacity: 0;
                border-radius: 50px;
                background-color: #ffffffaa;
                box-shadow: 0 0 15px #ffffffaa;
                position: absolute;
                left: calc(25% - 100px);
                bottom: -25px;
                animation: growFade 1s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                animation-delay: 0.8s;
            }
        }
    }
}


@keyframes growFade {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: scale(2);
    }
}

@keyframes flyingStars {
    0% {
        opacity: 1;
        transform: rotate(0deg);
        left: -20px;
        top: 50%;
    }

    30% { 
        top: 25%;
    }

    50% {
        top: 20%;
        left: 40px;
    }

    70% {
        top: 50%;
    }

    100% {
        opacity: 1;
        transform: rotate(1800deg);
        left: 100px;
        top: 110%;
    }
}

@keyframes flyingStarsReverse {
    0% {
        opacity: 1;
        transform: rotate(0deg);
        right: -20px;
        top: 50%;
    }

    30% { 
        top: 25%;
    }

    50% {
        top: 20%;
        right: 40px;
    }

    70% {
        top: 50%;
    }

    100% {
        opacity: 1;
        transform: rotate(1800deg);
        right: 100px;
        top: 110%;
    }
}

@keyframes neutralFullHop {
    0% {
        transform: translateY(0px);
    }
    
    30% {
        transform: translateY(-30px);
    }
    
    60% {
        transform: translateY(0px);
    }

    80% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes neutralIdle {
    // TODO this speed will be based on mobility stat
    0% {
        transform: translateY(0px) translateX(0px);
    }
    10% {
        transform: translateY(-10px) translateX(-5px);
    }
    20% {
        transform: translateY(0px) translateX(-10px);
    }
    30% {
        transform: translateY(-10px) translateX(-5px);
    }
    40% {
        transform: translateY(0px) translateX(0px);
    }
    50% {
        transform: translateY(0px) translateX(-10px);
    }
    60% {
        transform: translateY(0px) translateX(10px);
    }
    70% {
        transform: translateY(0px) translateX(-10px);
    }
    80% {
        transform: translateY(0px) translateX(10px);
    }
    90% {
        transform: translateY(0px) translateX(-10px);
    }
    100% {
        transform: translateY(0px) translateX(0px);
    }
}

@keyframes comboLeft {
    0% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    10% {
        transform: translateY(-30px) translateX(0px) scale(1);
    }

    20% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    20% {
        transform: translateY(-30px) translateX(0px) scale(1);
    }

    30% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    35% {
        transform: translateY(0px) translateX(-5px) scale(1.2);
    }

    40% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    50% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    55% {
        transform: translateY(0px) translateX(-5px) scale(1.2);
    }

    60% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    70% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    75% {
        transform: translateY(0px) translateX(-5px) scale(1.2);
    }

    80% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    90% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    95% {
        transform: translateY(0px) translateX(-5px) scale(1.2);
    }

    100% {
        transform: translateY(0px) translateX(0px) scale(1);
    }
}

@keyframes comboRight {
    0% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    10% {
        transform: translateY(-30px) translateX(0px) scale(1);
    }

    20% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    20% {
        transform: translateY(-30px) translateX(0px) scale(1);
    }

    30% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    35% {
        transform: translateY(0px) translateX(5px) scale(1.2);
    }

    40% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    50% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    55% {
        transform: translateY(0px) translateX(5px) scale(1.2);
    }

    60% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    70% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    75% {
        transform: translateY(0px) translateX(5px) scale(1.2);
    }

    80% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    90% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    95% {
        transform: translateY(0px) translateX(5px) scale(1.2);
    }

    100% {
        transform: translateY(0px) translateX(0px) scale(1);
    }
}

@keyframes disadvantageRight {
    0% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    30% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    35% {
        transform: translateY(0px) translateX(5px) scale(0.9);
    }

    40% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    50% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    55% {
        transform: translateY(0px) translateX(5px) scale(0.9);
    }

    60% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    70% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    75% {
        transform: translateY(0px) translateX(5px) scale(0.9);
    }

    80% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    90% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    95% {
        transform: translateY(0px) translateX(5px) scale(0.9);
    }

    100% {
        transform: translateY(0px) translateX(0px) scale(1);
    }
}

@keyframes disadvantageLeft {
    0% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    30% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    35% {
        transform: translateY(0px) translateX(-5px) scale(0.9);
    }

    40% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    50% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    55% {
        transform: translateY(0px) translateX(-5px) scale(0.9);
    }

    60% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    70% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    75% {
        transform: translateY(0px) translateX(-5px) scale(0.9);
    }

    80% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    90% {
        transform: translateY(0px) translateX(0px) scale(1);
    }

    95% {
        transform: translateY(0px) translateX(-5px) scale(0.9);
    }

    100% {
        transform: translateY(0px) translateX(0px) scale(1);
    }
}

.effects {
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@keyframes respawn {
    0% {
        opacity: 0;
    }


    49% {
        opacity: 0;
    }

    50% {
        opacity: 1;
        filter: brightness(1);
    }

    60% {
        filter: brightness(5);
    }

    70% {
        filter: brightness(1);
    }

    80% {
        filter: brightness(5);
    }

    90% {
        filter: brightness(1);
    }

    100% {
        filter: brightness(5);
    }

    85% {
        filter: brightness(5);
    }

    90% {
        filter: brightness(1);
    }

    95% {
        filter: brightness(5);
    }

    100% {
        filter: brightness(1);
    }
}

@keyframes punishLeft {
    0% {
        transform: translateX(0px) scale(1);
    }
    
    10% {
        transform: translateX(-15px) scale(1);
    }
    
    20% {
        transform: translateX(15px) scale(2);
    }

    30% {
        transform: translateX(-5px) scale(1);
    }

    100% {
        transform: translateX(0px) scale(1);
    }
}

@keyframes punishRight {
    0% {
        transform: translateX(0px) scale(1);
    }
    
    10% {
        transform: translateX(15px) scale(1);
    }
    
    20% {
        transform: translateX(-15px) scale(2);
    }

    30% {
        transform: translateX(5px) scale(1);
    }

    100% {
        transform: translateX(0px) scale(1);
    }
}

@keyframes vulnerable {
    0% {
        transform: translateY(0px);
    }
    
    30% {
        transform: translateY(-30px);
    }

    50% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(100px);
    }
}

@keyframes recover {
    0% {
        transform: translateY(100px);
    }

    20% {
        transform: translateY(20px);
    }

    30% {
        transform: translateY(35px);
    }

    50% {
        transform: translateY(-15px);
    }

    60% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes selfDestruct {
    0% {
        transform: translateY(0px) translateX(0px);
    }

    15% {
        transform: translateY(-40px) translateX(40px);
    }

    20% {
        transform: translateY(0px) translateX(80px);
    }

    40% {
        transform: translateY(100px) translateX(100px);
        opacity: 1;
    }

    100% {
        transform: translateY(100px) translateX(100px);
        opacity: 0;
    }
}

@keyframes selfDestructReverse {
    0% {
        transform: translateY(0px) translateX(0px);
    }

    15% {
        transform: translateY(-40px) translateX(-40px);
    }

    20% {
        transform: translateY(0px) translateX(-80px);
    }

    40% {
        transform: translateY(100px) translateX(-100px);
    }

    100% {
        transform: translateY(100px) translateX(-100px);
    }
}

@keyframes edgeguard {
    0% {
        transform: translateY(0px) translateX(0px);
    }

    15% {
        transform: translateY(-40px) translateX(40px);
    }

    20% {
        transform: translateY(0px) translateX(80px);
    }

    40% {
        transform: translateY(-40px) translateX(40px);
    }

    60% {
        transform: translateY(0px) translateX(0px);
    }

    100% {
        transform: translateY(0px) translateX(0px);
    }
}

@keyframes edgeguardReverse {
    0% {
        transform: translateY(0px) translateX(0px);
    }

    15% {
        transform: translateY(-40px) translateX(-40px);
    }

    20% {
        transform: translateY(0px) translateX(-80px);
    }

    40% {
        transform: translateY(-40px) translateX(-40px);
    }

    60% {
        transform: translateY(0px) translateX(0px);
    }

    100% {
        transform: translateY(0px) translateX(0px);
    }
}

@keyframes edgeguarded {
    0% {
        left: -12.5%;
        transform: translateY(40px) translateX(0px);
    }

    20% {
        left: 12.5%;
        transform: translateY(0px) translateX(0px);
    }

    40% {
        left: 12.5%;
        transform: translateY(150px) translateX(0px);
    }

    100% {
        left: 12.5%;
        transform: translateY(150px) translateX(0px);
    }
}

@keyframes edgeguardedReverse {
    0% {
        right: -12.5%;
        transform: translateY(40px) translateX(0px);
    }

    20% {
        right: 12.5%;
        transform: translateY(0px) translateX(0px);
    }

    40% {
        right: 12.5%;
        transform: translateY(150px) translateX(0px);
    }

    100% {
        right: 12.5%;
        transform: translateY(150px) translateX(0px);
    }
}