@import "../../variables";

.regions-container {
    background: $colour-dark;
    color: #ffffff;

    .parent-region {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;

        &:first-of-type {
            > li {
                border-top: none;
            }
        }

        li {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }
        
        > li {
            flex: 100%;
            padding: 10px 20px;
            font-weight: bold;
            border-top: solid 1px #ffffff;
            border-bottom: solid 1px #ffffff;
            background-color: $colour-secondary;
        }

        > ul {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            padding: 10px 0;
        }

        .region {
            padding: 0;
            font-size: 14px;
            flex: calc(50% - 1px);
            border-right: solid 1px #ffffffaa;

            
            a {
                color: #ffffff;
                text-decoration: none;
            }
            
            > li {
                font-weight: bold;
                flex: 100%;
                padding: 10px;
                background-color: $colour-even-less-dark;
                border-top: solid 1px #ffffffaa;
                border-bottom: solid 1px #ffffffaa;
                height: 20px;
            }

            &:first-of-type,
            &:nth-of-type(2) {
                > li {
                    border-top: none;
                }
            }

            &:nth-of-type(2n) {
                border-right: none;
            }

            > ul {
                padding: 10px;
                width: 100%;
                
                li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-weight: 300;

                    a {
                        display: flex;

                        img {
                            margin-right: 10px;
                        }

                        span {
                            opacity: 0.6;
                            margin-right: 5px;
                            white-space: nowrap;
                            padding: 0 5px;
                            width: 10px;
                            text-align: left;
                            border-right: solid 1px $colour-highlight;
                        }
                    }

                    &.followed {
                        font-weight: 400;
                        a { 
                            color: $colour-highlight;
                        }
                    }
                    
                    &.human {
                        font-weight: 400;
                        a { 
                            color: $colour-secondary;
                        }
                    }

                    img {
                        display: block;
                        height: 24px;
                    }
                }
            }
        }
    }
}