.fighter.fighter-link {
    &.fighter-edgeguarding {
        &.edgeguarding-remote_bombs {
            animation: none;
        }
    }
}

.fighter.opponent-link {
    &.fighter-edgeguarded {
        &.edgeguarding-remote_bombs {
            &.fighter-one {
                animation: bombEdgeguarded 0.8s;
                animation-iteration-count: 1;
                animation-timing-function: linear;
                animation-fill-mode: forwards;
            }
            
            &.fighter-two {
                animation: bombEdgeguardedReverse 0.8s;
                animation-iteration-count: 1;
                animation-timing-function: linear;
                animation-fill-mode: forwards;
            }
        }
    }
}

.fighter-link-effects {
    &.fighter-one-effects {
        &.fighter-neutral-effects {
            &.strategy-campy-effects {
                > div:first-of-type {
                    animation: boomerang 1.5s infinite;
                    animation-delay: 0.5s;
                    opacity: 0;
                    background-image: url('/assets/effects/link/boomerang.png');
                    background-size: auto 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 24px;
                    width: 24px;
                    border-radius: 50%;
                    position: absolute;
                    left: calc(25% - 16px);
                    top: calc(65% - 32px);
                }
            }
        }
        
        &.edgeguarding-remote_bombs {
            > div:first-of-type {
                animation: remoteBomb 0.25s;
                animation-fill-mode: forwards;
                animation-timing-function: linear;
                animation-iteration-count: 1;
                background-image: url('/assets/effects/link/remote_bomb.png');
                background-size: 100%;
                background-position: center;
                height: 24px;
                width: 24px;
                border-radius: 50%;
                position: absolute;
                left: calc(75% - 16px);
                top: calc(65% - 32px);
          }
          
          > div:nth-of-type(2) {
                animation: remoteExplosion 0.5s;
                animation-fill-mode: forwards;
                animation-delay: 0.25s;
                animation-iteration-count: 1;
                background-color: rgb(154, 218, 246);
                height: 15px;
                width: 15px;
                border-radius: 50%;
                transform: scale(0.1);
                position: absolute;
                left: calc(75% - 16px + 50px);
                top: calc(65% - 32px + 15px);
          }
       }
    }

    &.fighter-two-effects {
        &.fighter-neutral-effects {
            &.strategy-campy-effects {
                > div:first-of-type {
                    animation: boomerangReverse 1.5s infinite;
                    animation-delay: 0.5s;
                    opacity: 0;
                    background-image: url('/assets/effects/link/boomerang.png');
                    background-size: auto 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 24px;
                    width: 24px;
                    border-radius: 50%;
                    position: absolute;
                    right: calc(25% - 16px);
                    top: calc(65% - 32px);
                }
            }
        }

        &.edgeguarding-remote_bombs {
            > div {
                animation: remoteBombReverse 0.25s;
                animation-fill-mode: forwards;
                animation-timing-function: linear;
                animation-iteration-count: 1;
                background-image: url('/assets/effects/link/remote_bomb.png');
                background-size: 100%;
                background-position: center;
                height: 24px;
                width: 24px;
                border-radius: 50%;
                position: absolute;
                right: calc(75% - 16px);
                top: calc(65% - 32px);
            }          
            
            > div:nth-of-type(2) {
                animation: remoteExplosion 0.5s;
                animation-fill-mode: forwards;
                animation-delay: 0.25s;
                animation-iteration-count: 1;
                background-color: rgb(154, 218, 246);
                height: 15px;
                width: 15px;
                border-radius: 50%;
                position: absolute;
                opacity: 0;
                right: calc(75% - 16px + 50px);
                top: calc(65% - 32px + 15px);
          }
        }   
    }
}

@keyframes remoteBomb {
    0% {
        transform: translateX(0px) translateY(0px) rotate(0deg);
    }
    
    50% {
        transform: translateX(25px) translateY(-15px) rotate(250deg);
    }
    
    99% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateX(50px) translateY(15px) rotate(500deg);
    }
}

@keyframes remoteBombReverse {
    0% {
        transform: translateX(0px) translateY(0px) rotate(0deg);
    }
    
    50% {
        transform: translateX(-25px) translateY(-15px) rotate(250deg);
    }

    99% {
        opacity: 1;
    }
    
    100% {
        transform: translateX(-50px) translateY(15px) rotate(500deg);
        opacity: 0;
    }
}

@keyframes remoteExplosion {
  0% {
    opacity: 0;
  }

  1% { 
    opacity: 1;
    transform: scale(0.1);
  }
  
  100% {
    opacity: 0;
    transform: scale(10);
  }
}

@keyframes bombEdgeguarded {
    0% {
        left: -12.5%;
        transform: translateY(40px) translateX(0px);
    }

    20% {
        left: 12.5%;
        transform: translateY(0px) translateX(0px);
    }

    40% {
        left: 10%;
        transform: translateY(0px) translateX(0px);
    }

    100% {
        left: 2.5%;
        transform: translateY(150px) translateX(0px);
    }
}

@keyframes bombEdgeguardedReverse {
    0% {
        right: -12.5%;
        transform: translateY(40px) translateX(0px);
    }

    20% {
        right: 12.5%;
        transform: translateY(0px) translateX(0px);
    }

    40% {
        right: 10%;
        transform: translateY(0px) translateX(0px);
    }

    100% {
        right: 2.5%;
        transform: translateY(150px) translateX(0px);
    }
}


@keyframes boomerang {
    0% {
        opacity: 1;
        left: 25%;
        transform: rotate(0deg);
    }

    45% {
        left: 70%;
    }

    50% {
        left: 75%;
    }

    55% {
        left: 70%;
    }

    100% {
        opacity: 1;
        left: 25%;
        transform: rotate(1080deg);
    }
}

@keyframes boomerangReverse {
    0% {
        opacity: 1;
        right: 25%;
        transform: rotate(0deg);
    }

    45% {
        right: 70%;
    }

    50% {
        right: 75%;
    }

    55% {
        right: 70%;
    }

    100% {
        opacity: 1;
        right: 25%;
        transform: rotate(1080deg);
    }
}