.fighter.fighter-fox,
.fighter.fighter-falco {
    &.fighter-neutral {
        &.opponent-strategy-campy {
            animation: none;
        }
    }

    &.fighter-recovering {
        transition: none;

        &.fighter-one {
            animation: foxRecovery 0.6s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            left: 25%;
        }

        &.fighter-two {
            animation: foxRecoveryReverse 0.6s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            right: 25%;
        }
    }
}

.fighter-fox-effects,
.fighter-falco-effects {
    &.fighter-neutral-effects {
        &.opponent-strategy-campy-effects {
            > div:first-of-type,
            > div:nth-of-type(2) {
                animation-name: reflector;
                animation-duration: 0.3s;
                animation-iteration-count: infinite;
                background-image: url('/assets/effects/fox/shine.png');
                height: 32px;
                width: 32px;
                position: absolute;
                top: calc(65% - 32px);
                opacity: 0;
                background-size: 100%;
                animation-delay: 0.5s;
            }

            > div:nth-of-type(2) { animation-delay: 0.65s; }

            &.fighter-one-effects {
                > div {
                    left: 25%;
                }
            }
            &.fighter-two-effects {
                > div {
                    right: 25%;
                }
            }
        }  
    } 

    &.fighter-one-effects {
        &.fighter-recovering-effects {
            div:first-of-type {
                animation: foxRecoveryEffect 0.5s;
                animation-timing-function: linear;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                height: 32px;
                width: 32px;
                position: absolute;
                top: calc(65% - 32px);
                left: 25%;
                background-image: url('/assets/effects/fox/fire.png');
                background-size: 32px;
                background-repeat: no-repeat;
                filter: brightness(2);
            }
        }
    }

    &.fighter-two-effects {
        &.fighter-recovering-effects {
            div:first-of-type {
                animation: foxRecoveryEffectReverse 0.5s;
                animation-timing-function: linear;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                height: 32px;
                width: 32px;
                position: absolute;
                top: calc(65% - 32px);
                right: 25%;
                background-image: url('/assets/effects/fox/fire.png');
                background-size: 32px;
                background-repeat: no-repeat;
                filter: brightness(2);
            }
        }
    }
}

.fighter-falco-effects {
    &.fighter-neutral-effects {
        &.opponent-strategy-campy-effects {
            &.fighter-one-effects {
                height: 100%;
                animation: reflectorThrow 0.75s infinite;
                animation-timing-function: ease-in-out;
                animation-delay: 0.5s;
            }
            
            &.fighter-two-effects {
                height: 100%;
                animation: reflectorThrowReverse 0.75s infinite;
                animation-timing-function: ease-in-out;
                animation-delay: 0.5s;
            }
        }
    }
}

@keyframes reflector {
    0% {
        transform: scale(0.1);
        opacity: 0.7;
    }

    100% {
        transform: scale(1);
        opacity: 0.7;
    }
}

@keyframes reflectorThrow {
    0% {
        transform: translateX(0px);
        opacity: 1;
    }

    50% {
        transform: translateX(80px);
    }

    85% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateX(0px);
    }
}

@keyframes reflectorThrowReverse {
    0% {
        transform: translateX(0px);
        opacity: 1;
    }

    50% {
        transform: translateX(-80px);
    }

    85% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateX(0px);
    }
}

@keyframes foxRecovery {
    0% { 
        transform: translateX(-100px) translateY(100px) rotate(45deg);
        filter: hue-rotate(-45deg);
    }
    
    100% {
        transform: translateX(0px) translateY(0px) rotate(0deg);
        filter: hue-rotate(0deg);
    }
}

@keyframes foxRecoveryReverse {
    0% { 
        transform: translateX(100px) translateY(100px) rotate(-45deg);
        filter: hue-rotate(-45deg);
    }
    
    100% {
        transform: translateX(0px) translateY(0px) rotate(0deg);
        filter: hue-rotate(0deg);
    }
}


@keyframes foxRecoveryEffect {
    0% { 
        transform: translateX(-100px) translateY(100px) rotate(45deg);
        opacity: 1;
    }
    
    100% {
        transform: translateX(0px) translateY(0px) rotate(0deg);
        opacity: 0;
    }
}

@keyframes foxRecoveryEffectReverse {
    0% { 
        transform: translateX(100px) translateY(100px) rotate(-45deg);
        opacity: 1;
    }
    
    100% {
        transform: translateX(0px) translateY(0px) rotate(0deg);
        opacity: 0;
    }
}