@import "../../variables";

.home-container {
    background: $colour-dark;

    .weeklies {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .test-tournament {
        display: flex;
        font-size: 11px;
    }
}
