@import "../../variables";

.button {
    display: inline-block;
    padding: 5px 15px;
    border: solid 2px $colour-secondary;
    border-radius: 5px;
    color: $colour-secondary;
    text-decoration: none;
    transition: all 0.3s linear;
    text-align: center;
    margin: 10px 0;
    cursor: pointer;
    user-select: none;
    
    &.disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.4;
    }

    &:hover {
        border-color: $colour-highlight;
        color: $colour-highlight;
    }
        
    &.primary {
        border: none;
        padding: 7px 17px;
        background: linear-gradient(to bottom right, $colour-highlight, $colour-secondary);
        color: $colour-dark;
        position: relative;
        z-index: 1;

        &:before {
            content: ' ';
            background: linear-gradient(to bottom right, $colour-secondary, $colour-highlight);
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            transition: opacity 0.3s linear;
            border-radius: 5px;
        }

        &:hover {
            &:before {
                opacity: 1;
            }
        }
    }
}
