@import "../../variables";

.region-container {
    background: $colour-dark;
    color: #ffffff;

    .region {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;

        &:first-of-type {
            > li {
                border-top: none;
            }
        }

        li {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }
        
        > li {
            flex: 100%;
            padding: 10px 15px;
            font-weight: bold;
            border-top: solid 1px #ffffff;
            border-bottom: solid 1px #ffffff;
            background-color: $colour-secondary;
        }

        > ul {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            padding: 0;

            > li {
                font-weight: 300;
                flex: 100%;
                padding: 10px 15px;
                background-color: $colour-even-less-dark;
                border-bottom: solid 1px #ffffff66;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;

                a {
                    flex: 100%;
                    font-size: 16px;
                    color: #ffffff;
                    text-decoration: none;
                }

                img {
                    display: block;
                    height: 24px;
                    margin-right: 10px;
                }

                .button {
                    margin-left: 10px;
                    line-height: 14px;
                    padding: 5px 10px;
                    transition: none;

                    &.primary {
                        padding: 7px 17px;
                    }
                }
            }
        }
    }
}