.fighter.fighter-mario {
    &.fighter-edgeguarding {
        &.edgeguarding-fludd {
            animation: none;
        }
    }
}

.fighter.opponent-mario {
    &.fighter-edgeguarded {
        &.edgeguarding-fludd {
            &.fighter-one {
                animation: fluddEdgeguarded 0.8s;
                animation-iteration-count: 1;
                animation-timing-function: linear;
                animation-fill-mode: forwards;
            }
            
            &.fighter-two {
                animation: fluddEdgeguardedReverse 0.8s;
                animation-iteration-count: 1;
                animation-timing-function: linear;
                animation-fill-mode: forwards;
            }
        }
        
        &.edgeguarding-offstage_cape_swing {
            &.fighter-one {
                animation: capeEdgeguarded 0.8s;
                animation-iteration-count: 1;
                animation-timing-function: linear;
                animation-fill-mode: forwards;
            }
            
            &.fighter-two {
                animation: capeEdgeguardedReverse 0.8s;
                animation-iteration-count: 1;
                animation-timing-function: linear;
                animation-fill-mode: forwards;
            }
        }
    }
}

.fighter-mario-effects {
    &.fighter-one-effects {
        &.fighter-neutral-effects {
            &.strategy-campy-effects {
                div:first-of-type {
                    animation: fireball 0.75s;
                    animation-timing-function: linear;
                    animation-iteration-count: infinite;
                    height: 16px;
                    width: 16px;
                    position: absolute;
                    top: calc(65% - 16px);
                    background-image: url('/assets/effects/mario/fireball.png');
                    background-size: 16px;
                    background-repeat: no-repeat;
                }       
            }
        }
        
        &.edgeguarding-fludd {
            > div {
                animation: fludd 1.5s;
                animation-fill-mode: forwards;
                animation-iteration-count: 1;
                background-color: rgb(106, 144, 240);
                height: 15px;
                width: 15px;
                border-radius: 50%;
                position: absolute;
                left: calc(75% - 16px);
                top: calc(65% - 32px);

                &:nth-of-type(2) { animation-delay: 0.1s; }
                &:nth-of-type(3) { animation-delay: 0.2s; }
                &:nth-of-type(4) { animation-delay: 0.3s; }
                &:nth-of-type(5) { animation-delay: 0.4s; }
            }
        }
        
        &.edgeguarding-offstage_cape_swing {
            > div:first-of-type {
                transform-origin: top right;
                animation: capeSwing 0.25s;
                animation-fill-mode: forwards;
                animation-iteration-count: 1;
                animation-delay: 0.25s;
                transform: skew(10deg, -20deg) rotateY(90deg);
                background: linear-gradient(90deg, rgba(241,206,6,1) 0%, rgba(189,162,7,1) 100%);
                height: 16px;
                width: 12px;
                position: absolute;
                left: calc(75% + 32px);
                top: calc(65% - 32px);
            }
        }   
    }

    &.fighter-two-effects {
        &.fighter-neutral-effects {
            &.strategy-campy-effects {
                div:first-of-type {
                    animation: fireballReverse 0.75s;
                    animation-delay: 0.2s;
                    animation-timing-function: linear;
                    animation-iteration-count: infinite;
                    height: 16px;
                    width: 16px;
                    position: absolute;
                    top: calc(65% - 16px);
                    background-image: url('/assets/effects/mario/fireball.png');
                    background-size: 16px;
                    background-repeat: no-repeat;
                    opacity: 0;
                }
            }
        }

        &.edgeguarding-fludd {
            > div {
                animation: fluddReverse 1.5s;
                animation-fill-mode: forwards;
                animation-iteration-count: 1;
                background-color: rgb(106, 144, 240);
                height: 15px;
                width: 15px;
                border-radius: 50%;
                position: absolute;
                right: calc(75% - 16px);
                top: calc(65% - 32px);

                &:nth-of-type(2) { animation-delay: 0.1s; }
                &:nth-of-type(3) { animation-delay: 0.2s; }
                &:nth-of-type(4) { animation-delay: 0.3s; }
                &:nth-of-type(5) { animation-delay: 0.4s; }
            }
        }   
        
        &.edgeguarding-offstage_cape_swing {
            > div:first-of-type {
                transform-origin: top right;
                animation: capeSwingReverse 0.25s;
                animation-fill-mode: forwards;
                animation-iteration-count: 1;
                animation-delay: 0.25s;
                transform: skew(-10deg, 20deg) rotateY(-90deg);
                background: linear-gradient(90deg, rgba(241,206,6,1) 0%, rgba(189,162,7,1) 100%);
                height: 24px;
                width: 16px;
                position: absolute;
                right: calc(75% + 32px);
                top: calc(65% - 32px);
            }
        }   
    }
}

@keyframes fludd {
    0% {
        opacity: 0.9;
        transform: translateX(0px);
        filter: blur(1px);
    }
    
    50% {
        transform: translateX(75px) translateY(-10px);
    }
    
    100% {
        opacity: 0.1;
        transform: translateX(150px) translateY(40px);
        filter: blur(10px);
    }
}

@keyframes fluddReverse {
    0% {
        opacity: 0.9;
        transform: translateX(0px);
        filter: blur(1px);
    }
    
    50% {
        transform: translateX(-75px) translateY(-10px);
    }
    
    100% {
        opacity: 0.1;
        transform: translateX(-150px) translateY(40px);
        filter: blur(10px);
    }
}


@keyframes fluddEdgeguarded {
    0% {
        left: -12.5%;
        transform: translateY(40px) translateX(0px);
    }

    20% {
        left: 12.5%;
        transform: translateY(0px) translateX(0px);
    }

    40% {
        left: 10%;
        transform: translateY(0px) translateX(0px);
    }

    100% {
        left: 2.5%;
        transform: translateY(150px) translateX(0px);
    }
}

@keyframes fluddEdgeguardedReverse {
    0% {
        right: -12.5%;
        transform: translateY(40px) translateX(0px);
    }

    20% {
        right: 12.5%;
        transform: translateY(0px) translateX(0px);
    }

    40% {
        right: 10%;
        transform: translateY(0px) translateX(0px);
    }

    100% {
        right: 2.5%;
        transform: translateY(150px) translateX(0px);
    }
}

@keyframes fireball {
    0% {
        left: 25%;
        opacity: 0;
        transform: translateY(0px) rotate(0deg);
    }

    32% { 
        left: 25%;
        opacity: 0;
        transform: translateY(0px) rotate(360deg);
    }

    33% { 
        transform: translateY(-30px) rotate(361deg);
    }

    40% {
        opacity: 1;
    }

    50% { 
        transform: translateY(-20px) rotate(540deg);
    }

    66% { 
        transform: translateY(0px) rotate(720deg);
    }

    83% { 
        transform: translateY(-20px) rotate(900deg);
        opacity: 1;
    }

    100% {
        transform: translateY(0px) rotate(1080deg);
        left: 75%;
        opacity: 0;
    }
}

@keyframes fireballReverse {
    0% {
        right: 25%;
        opacity: 0;
        transform: translateY(0px) rotate(0deg);
    }

    32% { 
        right: 25%;
        opacity: 0;
        transform: translateY(0px)  rotate(360deg);
    }

    33% { 
        opacity: 1;
        transform: translateY(-30px) rotate(361deg);
    }

    50% { 
        transform: translateY(-20px) rotate(540deg);
    }

    66% { 
        transform: translateY(0px) rotate(720deg);
    }

    83% { 
        transform: translateY(-20px) rotate(900deg);
        opacity: 1;
    }

    100% {
        transform: translateY(0px) rotate(1080deg);
        right: 75%;
        opacity: 0;
    }
}

@keyframes capeSwing {
    0% { 
        transform: skew(10deg, -20deg) rotateY(90deg);
    }
    
    100% {
        transform: skew(-21deg, 20deg) rotateY(-90deg);
    }
}

@keyframes capeSwingReverse {
    0% { 
        transform: skew(-10deg, 20deg) rotateY(-90deg);
    }
    
    100% {
        transform: skew(21deg, -20deg) rotateY(90deg);
    }
}

@keyframes capeEdgeguarded {
    0% {
        left: -12.5%;
        transform: translateY(40px) translateX(0px);
    }

    50% {
        left: 12.5%;
        transform: translateY(0px) translateX(0px);
    }

    100% {
        left: -12.5%;
        transform: translateY(150px) translateX(0px);
    }
}

@keyframes capeEdgeguardedReverse {
    0% {
        right: -12.5%;
        transform: translateY(40px) translateX(0px);
    }

    50% {
        right: 12.5%;
        transform: translateY(0px) translateX(0px);
    }

    100% {
        right: -12.5%;
        transform: translateY(150px) translateX(0px);
    }
}